import {
    makeStyles
}

    from "@material-ui/core/styles";
const screenWidth = window.innerWidth + 140;
const closeWIdth = window.innerWidth - 100;
const screenheight = window.innerHeight - 120;
const useStyles = makeStyles(theme => ({

    closeIcon: {
        position: 'absolute',
        right: '0px !important',
        marginTop: '10px',
        zIndex: '2',
        width: '30px',
        "@media only screen and (min-width: 576px)": {
            transform: 'translateX(550%)',
            left: '50%',

        },
    },
    carouselImg: {
        // maxHeight: screenheight,
        height: '200px !important',
        width: '200px !important',
        borderRadius: '0px',
        objectFit: '',
    },

    dialog: {
        bottom: '0', borderRadius: '0px', position: 'absolute', height: screenWidth, margin: "0px !important"
        // "@media only screen and (max-width: 325px) and (min-width: 278px)": {
        //     bottom: '0', borderRadius: '0px', position: 'absolute', height: '74% !important', margin: "0px !important"
        // },
        // "@media only screen and (max-width: 359px) and (min-width: 326px)": {
        //     bottom: '0', borderRadius: '0px', position: 'absolute', height: '60% !important', margin: "0px !important"
        // },
        // "@media only screen and (max-width: 380px) and (min-width: 360px)": {
        //     bottom: '0', borderRadius: '0px', position: 'absolute', height: '70% !important', margin: "0px !important"
        // },
        // "@media only screen and (max-width: 414px) and (min-width: 400px)": {
        //     bottom: '0', borderRadius: '0px', position: 'absolute', height: '64% !important', margin: "0px !important"
        // },
        // "@media only screen and (max-width: 400px) and (min-width: 381px)": {
        //     bottom: '0', borderRadius: '0px', position: 'absolute', height: '57% !important', margin: "0px !important"
        // },
        // "@media only screen and (max-width: 475px) and (min-width: 415px)": {
        //     bottom: '0', borderRadius: '0px', position: 'absolute', height: '57% !important', margin: "0px !important"
        // }
    },

    botm_dialog: {
        borderTopLeftRadius: "30px !important",
        borderTopRightRadius: "30px !important",
        borderBottomLeftRadius: "0 !important",
        borderBottomRightRadius: "0 !important",
        minHeight: '200px',
        "@media only screen and (min-width: 576px)": {
            maxWidth: '410px'

        },
    },

    scrollableDialog: {
        '& .MuiDialogContent-root': {
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px',
            },
        },
        "@media only screen and (min-width: 576px)": {
            width: '410px'
        },
    },

    headerTabStyle: {
        width: "100%",
        marginRight: "0",
        marginLeft: "auto",
        marginTop: "0",
        marginBottom: "auto",
        backgroundColor: "white",
        color: "black",
        borderRadius: "0",
        overflow: "hidden",
    },

    menuDialog: {
        '& .MuiDialogContent-root': {
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px',
            },
        },
        width: '70%',
        height: '270px',
        marginRight: '10px',
        marginLeft: 'auto',
        marginTop: 'auto',
        marginBottom: '80px',
        backgroundColor: '#3a3a3c',
        color: 'white',
        borderRadius: '20px',
        overflow: 'hidden',
        maxWidth: '307px',
        "@media only screen and (min-width: 576px)": {
            transform: 'translateX(12%) !important',
            marginRight: 'auto',
        },
    },

    closeIconForCall: {
        position: 'fixed',
        right: '5%',
        zIndex: '2',
        marginTop: '-18px',
        width: '30px',
        "@media only screen and (min-width: 406px) ": {
            right: '8%'
        },
        "@media only screen and (max-width: 406px) and (min-width: 249px)": {
            right: '5%'
        },
        "@media only screen and (min-width: 467px) ": {
            right: '10%'
        },
    },
    bigscreens: {
        "@media only screen and (min-width: 576px)": {
            width: '410px'
        },
    },
    menuBar: {
        "@media only screen and (min-width: 576px)": {
            left: '50%',
            transform: 'translateX(-55%)',
            width: '390px !important',
        },
    },
    aboutUs: {
        "@media only screen and (min-width: 576px)": {
            maxWidth: '350px'
        },
        borderRadius: '10px',
        minHeight: '300px'
    },
    iframe: {
        "@media only screen and (min-width: 576px)": {
            maxWidth: '350px'
        },
        borderRadius: '10px',
        minHeight: '300px',
        height: '400px',
        overflowY: 'hidden'
    }

}
));
export default useStyles;