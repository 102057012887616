import React from "react";
import blue from "../../../assets/images/bluebg.jpg";
import orange from "../../../assets/images/orangebg.jpg";
import img7 from "../../../assets/images/play-store.png";
import "./index.scss";

const CancellationRefunds = () => {
  return (
    <div>
      <div className="agreement">
        <div
          className="con"
          style={{
            backgroundImage: `url(${blue})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "revert",
          }}
        >
          <div style={{ paddingTop: "40px" }} className="col">
            <div className="user">
              <div className="user__heading">
                <h1>CANCELLATION, REFUND, RETURN AND EXCHANGE POLICY</h1>
              </div>
            </div>
          </div>
          <div className="user__content1">
            <p>
              Chattybao Technologies Private Limited (“Chattybao”) operates the
              chattybao.com website and the chattybao mobile application
              (“Platform”).
            </p>
            <h6 className="header_tag">Customer cancellation:</h6>
            <p className="p-tag">
              As a general rule, Buyer shall not be entitled to cancel Order
              once payment is done after placing the order. Buyer may choose to
              cancel Order only before the payment is done. After the payment is
              done, buyer may request the merchant to cancel the order by
              calling the merchant or requesting cancellation over chat, however
              in such cases, order cancellation will be at the sole discretion
              of the merchant. However, subject to Buyer’s previous cancellation
              history, ChattyBao reserves the right to deny any refund to Buyer
              pursuant to a cancellation initiated by Buyer followed by
              suspension of account, as may be necessary in the sole discretion
              of ChattyBao.
              <br />
              <br />
              If Buyer cancels his/her after making the payment, ChattyBao shall
              have a right to collect a Cancellation Fee of 100% of the Order
              amount for breach of contract terms as a compensation for the
              damages suffered by ChattyBao, with a right to not to refund the
              Order value, to compensate the Merchants and the delivery
              services.
            </p>
            <h6 className="header_tag">Non customer cancellation:</h6>
            <p className="p-tag">
              In case of cancellations for the reasons attributable to ChattyBao
              or the Merchant or delivery partner, ChattyBao shall not collect
              any Cancellation Fee from the Buyer.
              <br />
              <br />
              ChattyBao reserves the right to collect a Cancellation Fee for the
              Orders constrained to be cancelled by ChattyBao for reasons not
              attributable to ChattyBao, including but not limited to:
              <br />
              <br />- in the event if the address provided by Buyer is either
              wrong or falls outside the delivery zone;
              <br />
              <br />- failure to contact Buyer by phone or email at the time of
              delivering the Order booking;
              <br />
              <br />- failure to deliver Buyer Order due to lack of information,
              direction or authorization from Buyer at the time of delivery; or
              <br />
              <br />- unavailability of all the items ordered by Buyer at the
              time of booking the Order. However, in the unlikely event of an
              item in an Order being unavailable, Merchant will contact the
              Buyer on the phone number provided to ChattyBao at the time of
              placing the Order and inform Buyer of such unavailability. In such
              an event Buyer will be entitled to cancel the entire Order and
              shall be entitled to a refund to an amount upto 100% of the Order
              value.
            </p>
            <h6 className="header_tag">
              What is Cancellation Fee and How will it be charged?
            </h6>
            <p className="p-tag">
              A cancellation fee may be levied on a Buyer on account of
              cancellation of orders by customer on the Platform (“Cancellation
              Fee”). Cancellation Fee is charged to compensate for the slot,
              time and effort incurred in processing an order by the seller and
              to compensate the logistics service providers for incurring a cost
              when they ship the order.
              <br />
              <br /> Chattybao reserves the right to modify/waive off the
              Cancellation fee from time to time. The Cancellation fee shall be
              quoted in Indian Rupees.
              <br />
              <br />
              The Cancellation Fee will be deducted from the amount paid by the
              buyer for the said cancelled order.
            </p>
            <h6 className="header_tag">When will the buyer receive refund?</h6>
            <p className="p-tag">
              If the buyer had not made the payment for the order, or request
              merchant for Cash on Delivery, there is no amount to be refunded
              because buyer hasn't paid for the order.
              <br />
              <br />
              If a buyer requested merchant for Cash on Delivery order, and
              subsequently requested refund, ChattyBao will have no role to play
              in the refund process as payment transaction, if any, is directly
              between the buyer and the merchant’s personal account. The buyer
              shall coordinate directly with the merchant in such cases, using
              chat or contact details provided on ChattyBao platform.
              <br />
              <br />
              The Buyer may be entitled to a refund for prepaid Orders, post
              deduction of cancellation fee, if any for reasons mentioned above
              or in a manner as deemed fit by ChattyBao in its sole discretion,
              if refund has been requested due to the following reasons:
              <br />
              <br />- if the Merchant cancels the Order or fails to process the
              order due to reasons not attributable to the Buyer, including but
              not limited to store being closed, non-availability of items,
              store not servicing online Orders, overcrowding at store, etc.;
              <br />
              <br />- If ChattyBao cancels the Order due to reasons not
              attributable to the Buyer, including but not limited to
              non-availability of delivery partners, etc.
              <br />
              <br />
              The Buyer may be entitled to refund due to the aforesaid reasons
              upto 100% of the Order value depending upon the nature of issue.
              ChattyBao reserves the right to consider the cancellation and
              refund request and determine if such cancellation and refund
              request satisfy any of the aforesaid conditions, in which case
              ChattyBao shall process the cancellation request and refund to
              Buyer.
              <br />
              <br />
              ChattyBao’s decision on refunds shall be final and binding.
              <br />
              <br />
              All refund amounts shall be credited to Buyer’s account as may be
              stipulated as per the payment mechanism of Buyer’s choice, the
              estimated timelines are detailed as below:
              <br />
              <br />
              For payments made via UPI, you will receive refund into the source
              account within 72 hours. For payments made via Credit Card, Debit
              Card, Net Banking, or Wallet you will receive refund into the
              source account within 7-10 days from the time of order
              cancellation. In case of delay, you may contact our customer
              support team whose contact details are given in our website.
              <br />
              <br />
              <span style={{ color: "black", fontWeight: "500" }}>
                Important note:{" "}
              </span>
              In case of the complaint of any spurious product, the liability
              shall solely lie with the Merchant selling the product. ChattyBao
              is merely facilitating the transactions between the Merchant and
              the Buyer and therefore, ChattyBao shall assume no liability with
              respect to the products sold by the Merchant. ChattyBao strictly
              discourages dealing of any spurious product on its Platform and
              shall reserve the right to report such incidents to the concerned
              authorities for appropriate legal actions.
            </p>
            <h6 className="header_tag">RETURN AND EXCHANGE:</h6>
            <p className="p-tag">
              ChattyBao platform does not offer any return or exchange on the
              platform. If a buyer has a requirement for return, or exchange,
              the buyer may directly interact and coordinate with the merchant,
              using the merchant contact information provided by ChattyBao.
            </p>
          </div>
        </div>
      </div>
      <div
        className="container1"
        style={{
          backgroundImage: `url(${orange})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "revert",
        }}
      >
        <div className="con-2">
          <div className="con-2__content">
            <h1>Have a Business ?</h1>
            <h2>5 minute Sign up</h2>
            <div className="con-2__link">
              <a
                href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                target="_blank"
                rel="noreferrer"
              >
                <img src={img7} alt="" />
              </a>
              <div className="con-2__link-content">
                <a
                  href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Download Now</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationRefunds;
