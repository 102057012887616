import React, { useEffect, useState } from 'react';
import { CardContent, Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";
import { useDispatch, useSelector } from 'react-redux';
import { setState } from '../../redux/reduxStore';
import { commonFunctions } from '../../utils/CommonFunctions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ApiService from '../../utils/ApiService';
import useStyles from '../../components/Card/styles';
import { Card } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { Constants } from '../../utils/Constants';
import { APIConfig } from '../../utils/apiConfig';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';

import imageNotAvailable from '../../../assets/image-not-available.png';
import discount_tag from '../../../assets/Green_Discount_Tag.png';
import bestSeller_tag from '../../../assets/Bestseller_Tag.png';
import share_icon from '../../../assets/share_icon.png';
import close_btn from '../../../assets/close_btn.png';
import confirm_btn from '../../../assets/Confirm_Button.png';
import { ToastContainer, toast } from 'react-toastify';


const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variantWidth: false
};

const ProductPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const merchantdetails = JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails
    const { returnValue } = location.state || {};
    const classes = useStyles();
    const currentPath = location.pathname;
    const basePath = currentPath.split('/product/')[0];
    const { currency, calcOffer, getLongId } = commonFunctions;
    const { aboutUsPopupStyle, productTncText, userCashbackTermsPage, toastStyle } = Constants;
    const { iframeUrl } = APIConfig;
    const [loader, setLoader] = useState(false);
    const [productDetails, setProductDetails] = useState({});
    const [cartData, setCartData] = useState([]);
    const [offerBanner, setOfferBanner] = useState(merchantdetails.userOfferBanner);
    const totalCount = useSelector((state) => state.counter.totalCount);
    const totalPrice = useSelector((state) => state.counter.totalPrice);
    const loginPage = useSelector((state) => state.counter.loginPage);
    const iframeLoader = useSelector((state) => state.counter.iframeLoader);
    const [variantsVisible, setVariantsVisible] = useState(false);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const { productId } = useParams();

    // var mainMobileVisible = true;

    // const indexToSwap = merchantdetails.mobile_numbers_enable_on_website.indexOf(merchantdetails.mainmobilenumber);

    // // Check if the valueToCheck is in the array
    // if (indexToSwap !== -1) {
    //     // Make a copy of the array to avoid directly mutating the state
    //     const newArray = [...merchantdetails.mobile_numbers_enable_on_website];

    //     // Swap the first element with the element containing the valueToCheck
    //     const temp = newArray[0];
    //     newArray[0] = newArray[indexToSwap];
    //     newArray[indexToSwap] = temp;
    //     merchantdetails.mobile_numbers_enable_on_website = newArray

    //     // Update the state with the new array
    // } else {
    //     mainMobileVisible = false
    // }


    useEffect(() => {
        getProductData();
        dispatch(setState({ key: "loginPage", value: false }));
    }, [])

    useEffect(() => {  // iframe listener
        const handleMessage = (event) => {
            if (event.data && event.data.type === 'storeData') {
                const data = event.data.data;
                // localStorage.setItem('yourDataKey', data);
                console.log("Data", data)
                if (data) {
                    // setLoginPage(false);
                    dispatch(setState({ key: "loginPage", value: false }))
                    localStorage.setItem("User", data.phoneNumber);
                    localStorage.setItem("sessionId", data.sessionToken);
                    getChannelDetailsv3()
                    getMerchantDet(data.sessionToken)
                    console.log("getmerchant EXECUTED")
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const getMerchantDet = async (sessionId) => { // calling api to get latest banner after login
        const token = sessionId;

        const data = {
            merchantId: merchantdetails?.merchantid,
            ulatitude: '16.43',
            ulongitude: '74.59'
        }
        try {
            const result = await ApiService.getMerchantDataForCatalogue(data, token);
            if (result?.data?.code == 0) {
                console.log('result.data : ', JSON.stringify(result.data))
                localStorage.setItem("merchantdetails", JSON.stringify(result.data));
                setOfferBanner(result.data.merchantDetails.userOfferBanner)
            } else {
                console.log("Error")
            }
        } catch (error) {
            alert(error.message)
        } finally {

        }
    }

    const getChannelDetailsv3 = async () => { // calling api to check if order is there after login
        setLoader(true);
        const token = localStorage.getItem('sessionId');
        const mId = merchantdetails.merchantid;
        const data = {
            merchantId: mId,
        }
        try {
            setLoader(true);
            const result = await ApiService.getChannelDetailsv3(data, token);
            if (result?.data?.data?.is_checkout_order == 0) {
                if ((result?.data?.data?.delivery_mode == 0 && result.data.data.shipment_status === 5) || result.data.data.overall_status === 1 || result.data.data.PaymentDone === 1) {
                    handleviewcart()
                } else {
                    setOrderPlaced(true);
                    localStorage.removeItem("cartData")
                    dispatch(setState({ key: "totalCount", value: 0 }));
                }
            } else {
                handleviewcart();
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoader(false)
        }
    }

    const mapObjectProperties = (obj) => {
        return {
            id: obj.id,
            country: obj.country,
            item_name: obj.item_name,
            desc: obj.desc,
            image_url: obj.image_url,
            price: obj.rate,
            unit: obj.unit,
            count: 0,
            instruction: "",
            offer: obj.offer,
            discounted_rate: obj.discounted_rate,
            foodType: obj.foodType,
            variants: obj.variants,
            s1category: obj.category,
            bestseller: obj.bestseller,
            brandName: obj.brandName,
            is_product_available: obj.is_product_available,
            avail_msg: obj.avail_msg,
            status: obj.status
        };
    };

    const getProductData = async () => { // api call to get product data
        // const longId = getLongId(productId)
        const mId = merchantdetails.merchantid;
        const data = {
            merchant_id: mId,
            product_id: productId,
        }
        try {
            setLoader(true);
            const result = await ApiService.getProductDetails(data);
            if (result?.data?.code == 0) {
                const mappedObj = mapObjectProperties(result?.data?.data);
                const cart = (JSON.parse(localStorage.getItem("cartData")));
                if (cart) {
                    setCartData(cart);
                    let productInCart = cart.find(data => data.id === mappedObj.id);
                    if (productInCart) {
                        mappedObj.count = productInCart.count;
                    }
                }
                if (mappedObj.variants && mappedObj.variants.length !== 0) {
                    mappedObj.variants.map(varData => {
                        varData.count = 0;
                        varData.instruction = ""
                    })
                }
                setProductDetails(mappedObj);
            } else {
                alert('No Product Found')
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoader(false)
        }
    }

    const addCountForVariant = (count, id, parentId) => {
        count = count + 1
        let cart = cartData;
        const varIndex = productDetails.variants.map((item) => item.varientId).indexOf(id);
        productDetails.variants[varIndex].count = count
        productDetails.count++
        console.log('cart modification : ', cart);
        const cartIndex = cart.map((item) => item.id).indexOf(parentId);
        if (cartIndex !== -1) {

            cart[cartIndex].count = productDetails.count
            cart[cartIndex].variants[varIndex].count = count
        } else {
            cart.push(productDetails)
        }
        setCartData(cart)
        localStorage.setItem("cartData", JSON.stringify(cart));

        let filterdata = { ...productDetails };
        setProductDetails(filterdata);

        //  Calulations
        let varcountsum = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    varcountsum.push(cartData[i].variants[j].count)
                }
            }

        }

        var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
        let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
        let countsum = filterData.map((data) => data.count);
        var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
        Sumcount = Sumcount + varSumcount;
        dispatch(setState({ key: "totalCount", value: Sumcount }));

        let varcountprice = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    let data = (cartData[i].variants[j])
                    varcountprice.push(data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== "" && data.variantDisPrice !== undefined) ? data.count * parseFloat(data.variantDisPrice) : data.count * parseFloat(data.variantPrice)) : 0)
                }
            }

        }
        let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
        var varSumprice = varcountprice.reduce((partialSum, a) => partialSum + a, 0);
        var Sumprice = countprice.reduce((partialSum, a) => partialSum + a, 0);
        Sumprice = Sumprice + varSumprice;
        dispatch(setState({ key: "totalPrice", value: Sumprice }));

        let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
        var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
        let oldvarcountprice = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    let data = (cartData[i].variants[j])
                    oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
                }
            }

        }
        var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
        oldSumprice = oldSumprice + oldvarSumprice;
        dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
        dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
    };

    const decreaseCountForVariant = (count, id, parentId, value) => {
        count = count - 1
        let cart = cartData;
        const varIndex = productDetails.variants.map((item) => item.varientId).indexOf(id);
        const cartIndex = cart.map((item) => item.id).indexOf(parentId);
        if (cartIndex !== -1) {
            if (productDetails.count === 1) {
                cart.splice(cartIndex, 1);
                productDetails.count--
                productDetails.variants[varIndex].count--
            } else {
                cart[cartIndex].count = productDetails.count
                cart[cartIndex].variants[varIndex].count = count
                productDetails.count--

            }
        } else {
            cart.push(productDetails)
        }
        setCartData(cart)
        localStorage.setItem("cartData", JSON.stringify(cart));

        let filterdata = { ...productDetails };
        setProductDetails(filterdata);

        //  Calulations
        let varcountsum = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    varcountsum.push(cartData[i].variants[j].count)
                }
            }

        }
        var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
        let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
        let countsum = filterData.map((data) => data.count);
        var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
        Sumcount = Sumcount + varSumcount;
        dispatch(setState({ key: "totalCount", value: Sumcount }));

        let varcountprice = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    let data = (cartData[i].variants[j])
                    varcountprice.push(data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== "" && data.variantDisPrice !== undefined) ? data.count * parseFloat(data.variantDisPrice) : data.count * parseFloat(data.variantPrice)) : 0)
                }
            }

        }
        let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
        var varSumprice = varcountprice.reduce((partialSum, a) => partialSum + a, 0);
        var Sumprice = countprice.reduce((partialSum, a) => partialSum + a, 0);
        Sumprice = Sumprice + varSumprice;
        dispatch(setState({ key: "totalPrice", value: Sumprice }))

        let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
        var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
        let oldvarcountprice = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    let data = (cartData[i].variants[j])
                    oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
                }
            }

        }
        var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
        oldSumprice = oldSumprice + oldvarSumprice;
        dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
        dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
    };

    const addCount = () => {
        productDetails.count++;
        let cart = cartData;
        const cartIndex = cart.findIndex((item) => item.id === productDetails.id);
        console.log('cart modification 1 : ', cart[cartIndex]);

        if (cartIndex !== -1) {
            cart[cartIndex].count = productDetails.count;
            cart[cartIndex].rate = cart[cartIndex].price;
        } else {
            cart.push(productDetails)
        }
        setCartData(cart)
        localStorage.setItem("cartData", JSON.stringify(cart));

        let filterdata = { ...productDetails };
        setProductDetails(filterdata);

        //  Calulations
        let filterData = cartData.filter((data) => !data.variants || data.variants.length === 0)
        let countsum = filterData.map((data) => data.count);
        let varcountsum = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    varcountsum.push(cartData[i].variants[j].count)
                }
            }

        }
        var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
        var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
        Sumcount = Sumcount + varSumcount;
        dispatch(setState({ key: "totalCount", value: Sumcount }));
        let varPrice = [];
        let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : (data.price === 0 ? 0 : data.count * parseFloat(data.price))) : 0));

        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    if (cartData[i].variants[j].count > 0) {
                        if (cartData[i].variants[j].variantDisPrice !== undefined && cartData[i].variants[j].variantDisPrice !== null && cartData[i].variants[j].variantDisPrice !== '') {
                            varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantDisPrice))
                        } else {
                            varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantPrice))
                        }

                    }
                }
            }

        }
        var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
        var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
        var Sumprice = otherPrice + ttPrice;
        dispatch(setState({ key: "totalPrice", value: Sumprice }))

        let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
        var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
        let oldvarcountprice = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    let data = (cartData[i].variants[j])
                    oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
                }
            }

        }
        var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
        oldSumprice = oldSumprice + oldvarSumprice;
        dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
        dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
    };

    const decreaseCount = (count, id, value) => {
        productDetails.count--;
        let cart = cartData;
        const cartIndex = cart.findIndex((item) => item.id === productDetails.id);
        console.log('cart modification 1 : ', cart[cartIndex]);

        if (cartIndex !== -1) {
            cart[cartIndex].count = productDetails.count;
            cart[cartIndex].rate = cart[cartIndex].price;
        } else {
            cart.push(productDetails)
        }
        setCartData(cart)
        localStorage.setItem("cartData", JSON.stringify(cart));

        let filterdata = { ...productDetails };
        setProductDetails(filterdata);

        //  Calulations
        let filterData = cartData.filter((data) => !data.variants || data.variants.length === 0)
        let countsum = filterData.map((data) => data.count);
        let varcountsum = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    varcountsum.push(cartData[i].variants[j].count)
                }
            }

        }
        var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
        var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
        Sumcount = Sumcount + varSumcount;
        dispatch(setState({ key: "totalCount", value: Sumcount }));
        let varPrice = [];
        let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : (data.price === 0 ? 0 : data.count * parseFloat(data.price))) : 0));

        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    if (cartData[i].variants[j].count > 0) {
                        if (cartData[i].variants[j].variantDisPrice !== undefined && cartData[i].variants[j].variantDisPrice !== null && cartData[i].variants[j].variantDisPrice !== '') {
                            varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantDisPrice))
                        } else {
                            varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantPrice))
                        }

                    }
                }
            }

        }
        var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
        var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
        var Sumprice = otherPrice + ttPrice;
        dispatch(setState({ key: "totalPrice", value: Sumprice }))

        let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
        var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
        let oldvarcountprice = [];
        for (let i = 0; i < cartData.length; i++) {
            if (cartData[i].variants && cartData[i].variants.length !== 0) {
                for (let j = 0; j < cartData[i].variants.length; j++) {
                    let data = (cartData[i].variants[j])
                    oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
                }
            }

        }
        var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
        oldSumprice = oldSumprice + oldvarSumprice;
        dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
        dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
    };

    const handleviewcart = () => {
        history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/cart` : '/cart')
    };

    const handleShare = async () => { // handle share button
        // Assuming you have an image URL
        if (productDetails?.image_url[0]) {
            let response;
            try {
                const imageUrl = productDetails.image_url[0];
                response = await fetch(imageUrl, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                        Origin: window.location.origin,
                    },
                });
            } catch (error) {
                response = 'error'
            }
            if (response !== 'error') {
                const blob = await response.blob();
                const file = new File([blob], 'image.jpg', { type: "image/jpeg" });
                if (navigator.share) {
                    navigator.share({
                        title: 'Hey, I found this product on ChattyBao',
                        text: `Check out ${productDetails?.item_name}`,
                        url: window.location.href,
                        files: file ? [file] : null
                    })
                        .then(() => console.log('Successful share'))
                        .catch((error) => { console.error('Error sharing link:', error); toast("Error sharing link:", { autoClose: 500 }); });
                } else {
                    console.log('Web Share API not supported');
                    toast("Web Share not supported", { autoClose: 500 });
                }
            } else {
                if (navigator.share) {
                    navigator.share({
                        title: 'Hey, I found this product on ChattyBao',
                        text: `Check out ${productDetails?.item_name}`,
                        url: window.location.href,
                    })
                        .then(() => console.log('Successful share'))
                        .catch((error) => console.error('Error sharing link:', error));
                } else {
                    console.log('Web Share API not supported');
                    toast("Web Share not supported", { autoClose: 500 });
                }
            }
        } else {
            if (navigator.share) {
                navigator.share({
                    title: 'Hey, I found this product on ChattyBao',
                    text: `Check out ${productDetails?.item_name}`,
                    url: window.location.href,
                })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.error('Error sharing link:', error));
            } else {
                console.log('Web Share API not supported');
                toast("Web Share not supported", { autoClose: 500 });
            }
        }


    };

    const handleLoad = () => {
        dispatch(setState({ key: "iframeLoader", value: false }))
    };

    // const handleWhatsapp = (phoneNumber) => {
    //     window.open(
    //         `https://api.whatsapp.com/send?phone=91${phoneNumber}&text=Enquiry from customer`
    //     );
    // };

    // function hasSingleNonEmptyValue(arr) {
    //     const nonEmptyValues = arr.filter(value => value !== '');
    //     return nonEmptyValues.length === 1;
    // }

    return (
        <div className='product-page-container'>
            {loader && (
                <LoadingSpinner />
            )}
            <ToastContainer className='toast-container' hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={toastStyle} />
            {Object.keys(productDetails).length !== 0 && (
                <div>
                    <div className='pd-page-share-view-container'>
                        <span className='chat-share-btn-container' onClick={() => history.push(basePath)}>
                            <p className='button pd-page-top-btn-text'>View All Items</p>
                        </span>
                        <span className='hor_divider'></span>
                        <span className='chat-share-btn-container' onClick={handleShare}>
                            <img src={share_icon} className='chat-share-icon' alt="share" />
                            <p className='button pd-page-top-btn-text'>Share</p>
                        </span>
                    </div>
                    <div className='relative-position'>
                        {productDetails.image_url.length > 1 ? (
                            <Slider {...sliderSettings}>
                                {productDetails.image_url.map((data, index) => (
                                    <TransformWrapper panning={{ disabled: true }}>
                                        <TransformComponent>
                                            <div
                                                className='slider-container'
                                            >
                                                <img
                                                    className='slider-image'
                                                    src={data}
                                                    alt="img"
                                                    height={363}
                                                    onClick={(e) => {
                                                        alert("0");
                                                        if (this.state.isMoving) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </TransformComponent>
                                    </TransformWrapper>
                                ))}
                            </Slider>
                        ) : (
                            <>
                                {productDetails.image_url[0] ? (
                                    <img src={productDetails.image_url[0]} className='full-width' alt="img" />
                                ) : (
                                    <img src={imageNotAvailable} className="no-image-product" alt="img" />
                                )}
                            </>
                        )}

                        {productDetails?.discounted_rate && (
                            <div>
                                <img src={discount_tag} className='product-page-offer-tag' alt='NA' />
                                <p className='ofr_text'>{calcOffer(productDetails?.discounted_rate, productDetails?.price)}%<br />OFF</p>
                            </div>
                        )}

                        {productDetails?.bestseller === 0 ? (
                            <img src={bestSeller_tag} className='product-page-bestseller-tag' alt='NA' />
                        ) : ''}
                    </div>

                    <div className='detail-container'>
                        <div className='pd_details'>
                            <p className="pd_brandName">{productDetails?.brandName}</p>
                            <p className='pd_name word-break'>{productDetails.item_name}</p>
                            <div className='product-page-price-add-container'>
                                <div className="product-page-price-container">
                                    <p className={productDetails?.discounted_rate ? 'old_price_product' : 'discount_price'}>{currency(productDetails.price)}</p>
                                    <p hidden={!productDetails?.discounted_rate} className='discount_price in-line'>{currency(productDetails.discounted_rate)}</p>
                                </div>
                                {productDetails.count > 0 ? (
                                    <span
                                        className='add-minus-container'
                                    >

                                        <div className="grid-add-box-filled-options add-minus-btn-product">
                                            <p className="plus_minus_text_non_grid pointer" onClick={productDetails?.variants && productDetails?.variants.length !== 0 ? () => setVariantsVisible(true) : decreaseCount}>-</p>
                                            <p className="product_count2">{productDetails.count}</p>
                                            <p className="plus_minus_text_non_grid pointer" onClick={productDetails?.variants && productDetails?.variants.length !== 0 ? () => setVariantsVisible(true) : addCount}>+</p>

                                        </div>
                                    </span>
                                ) : (productDetails?.is_product_available === 1 ? (
                                    <span
                                        className="maincard_button pd-unavailable-container"
                                    >
                                        <div className='product-availability-text-container'>
                                            <p className="product_availability_text">{productDetails?.avail_msg}</p>
                                        </div>
                                    </span>
                                ) : (
                                    <span
                                        className="maincard_button pointer pd-unavailable-container"
                                        onClick={productDetails?.variants && productDetails?.variants.length !== 0 ? () => setVariantsVisible(true) : addCount}
                                    >
                                        <div className='add-options-container'>
                                            {productDetails?.variants && productDetails?.variants?.length !== 0 ? (
                                                <p className="options_text">{`${productDetails?.variants?.length} Options`}</p>
                                            ) : (
                                                <p className="add_text">ADD</p>
                                            )}

                                        </div>
                                    </span>
                                ))}


                            </div>
                            {offerBanner && (
                                <div className='pd-page-offer-banner-container'>
                                    <img
                                        onClick={() => window.open(userCashbackTermsPage)}
                                        src={offerBanner}
                                        alt="img"
                                        className="banner-images"
                                    />
                                </div>
                            )}
                            <div hidden={!productDetails?.desc}>
                                <Divider />
                                <p className='desc_heading'>Product Description</p>
                                <p className='desc_text word-break'>{productDetails?.desc}</p>
                            </div>

                            <Divider />
                            <p className='desc_heading'>Terms & Conditions</p>
                            <p className='desc_text'>{productTncText}</p>
                            <Divider />
                        </div>
                    </div>
                </div>
            )}

            {totalCount > 0 ? (
                <div className="cart_bottom_bar" onClick={(localStorage.getItem("User") ? handleviewcart : () => { dispatch(setState({ key: "loginPage", value: true })); dispatch(setState({ key: "iframeLoader", value: true })); })}>
                    <p className="view_cart_item">
                        {totalCount && totalCount ? totalCount : 0} Items &#124; {totalPrice && totalPrice ? currency(totalPrice) : "₹ 0"}
                    </p>
                    <p className="view_cart_text">View Cart</p>
                </div>
            ) : (
                ""
            )}

            {/* Variants pop up */}
            <Dialog classes={{ paper: classes.botm_dialog }} open={variantsVisible} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => setVariantsVisible(!variantsVisible)}>
                <div className="dialog_instruction_options">
                    Options
                    <img
                        src={close_btn}
                        className="close_btn2"
                        onClick={() => {
                            setVariantsVisible(!variantsVisible);
                        }}
                        alt="close"
                    />
                </div>
                <DialogContent className='variants-popup-container'>
                    <div>

                        {productDetails?.variants && productDetails?.variants.map((datad) => {
                            let offer = '';
                            if (datad.variantDisPrice !== null && datad.variantDisPrice !== "" && datad.variantDisPrice !== undefined) {
                                offer = 100 - (datad.variantDisPrice / datad.variantPrice) * 100;
                                offer = Math.round(offer)
                            }
                            return (
                                <>
                                    <Card className="variantCard" key={datad.varientId}>
                                        <div className="variant_container">
                                            <div className='variants-offer-container'>
                                                {(offer !== '' && offer != 0) ? (
                                                    <div className='relative-position'>
                                                        <p className="variants-offer">{offer}% OFF</p>
                                                    </div>
                                                ) : ''}

                                            </div>
                                            <div className='variants-cards-container'>
                                                <CardContent className="main_image_container_variant" style={{ flex: 1 }}>
                                                    <Typography className="variant-name product-variant-name" color="text.secondary">
                                                        {datad.variantName}
                                                    </Typography>
                                                </CardContent>
                                                {(offer !== '' && offer != 0) ? (
                                                    <div className='product-variant-price-container'>


                                                        <Typography
                                                            variant="subtile1"
                                                            className={`variant-cut-price ${offer !== "" ? 'normal_item' : 'price_without_discount'}`}
                                                        >
                                                            {datad.variantPrice && datad.variantPrice ? currency(datad.variantPrice * 1) : "₹ 5"}
                                                        </Typography>

                                                        <div className='variant-prices-container'>
                                                            {offer !== "" && (
                                                                <Typography
                                                                    variant="subtile1"
                                                                    color="text.secondary"
                                                                    className="product_offer_price variant-prices-text"
                                                                >
                                                                    {datad.variantDisPrice && datad.variantDisPrice ? currency(datad.variantDisPrice * 1) : " "}
                                                                </Typography>
                                                            )}

                                                        </div>

                                                    </div>
                                                ) : (
                                                    <div className='product-variant-price-container'>
                                                        <div className='variant-prices-container'>
                                                            <Typography
                                                                variant="subtile1"
                                                                color="text.secondary"
                                                                className="product_offer_price variant-prices-text"
                                                            >
                                                                {datad.variantPrice && datad.variantPrice ? currency(datad.variantPrice * 1) : "₹ 5"}
                                                            </Typography>
                                                        </div>

                                                    </div>
                                                )}

                                                <CardContent className="pdLayover_addContainer" style={{ paddingLeft: "9%" }}>
                                                    {datad.count > 0 ? (
                                                        <span style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                                            <div className="grid-add-box-filled-options">
                                                                <p className="plus_minus_text_non_grid pointer" onClick={() => decreaseCountForVariant(datad.count, datad.varientId, datad.id, null)}>-</p>
                                                                <p className="product_count2">{datad.count}</p>
                                                                <p className="plus_minus_text_non_grid pointer" onClick={() => addCountForVariant(datad.count, datad.varientId, datad.id, null)}>+</p>
                                                            </div>
                                                        </span>
                                                    ) : (
                                                        <div onClick={() => addCountForVariant(datad.count, datad.varientId, datad.id, null)} className="add-options-btn-non-grid">
                                                            <p className="add_text">ADD</p>
                                                        </div>


                                                    )}

                                                </CardContent>
                                            </div>

                                        </div>


                                    </Card>
                                    {/* <Divider className="dividers" /> */}
                                </>
                            )
                        }
                        )}
                    </div>


                </DialogContent>
                <DialogActions style={{ display: "flex", justifyContent: "center", padding: "0px 0px 15px 0px" }}>
                    <img src={confirm_btn} style={{ height: "39px", width: "95%" }} onClick={() => setVariantsVisible(false)} alt='confirm' />
                </DialogActions>
            </Dialog>

            {/* iframe login */}
            <Dialog
                open={loginPage}
                fullScreen
                classes={{
                    paper: classes.iframe,
                }}
                PaperProps={{ sx: aboutUsPopupStyle }}
                onClose={() => dispatch(setState({ key: "loginPage", value: !loginPage }))}
            >
                <img
                    src={close_btn}
                    className="login-close-btn-iframe"
                    onClick={() => {
                        dispatch(setState({ key: "loginPage", value: !loginPage }))
                    }}
                    alt="close"
                />
                {/* <DialogContent> */}
                <div className="iframe-container">
                    {iframeLoader && <ClipLoader className="iframe-loader" color="#125d8d" />}
                    <iframe className="iframe-style" title="Login" id="iframe-id" src={iframeUrl} onLoad={handleLoad} />
                </div>
                {/* </DialogContent> */}
            </Dialog>

            {/* Whatsapp layover */}

            {/* <Dialog classes={{ paper: classes.botm_dialog }} open={whatsappOpen} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => { dispatch(setState({ key: "openPhone", value: false })); dispatch(setState({ key: "whatsappOpen", value: false })); }}>
                <img
                    src={close_btn}
                    className="call_close_btn"
                    onClick={() => dispatch(setState({ key: "whatsappOpen", value: false }))}
                    alt="close"
                />
                <p className="call-contact-us-header">
                    WhatsApp
                </p>
                <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
                    <div className="call-contact-container">
                        {(!merchantdetails.mobile_numbers_enable_on_website || merchantdetails.mobile_numbers_enable_on_website.length === 0) && (
                            <p className="no-contact-number-text">Business has not provided any contact number</p>
                        )}
                        {merchantdetails.mobile_numbers_enable_on_website && merchantdetails.mobile_numbers_enable_on_website.length !== 0 && (
                            merchantdetails.mobile_numbers_enable_on_website.map((data, index) => {
                                return (
                                    data ? (
                                        <>
                                            {console.log("MERRRR,", merchantdetails.mobile_numbers_enable_on_website)}
                                            <div onClick={() => handleWhatsapp(data)} className="phone-owner-container">
                                                <div style={{ width: '180px' }}>
                                                    <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                                                        <img src={whatsappGreen} style={{ height: '20px', width: '20px', marginRight: '10px' }} alt='call' />
                                                        <p className="phone_call_text phone-number-text">{data}</p>
                                                    </div>

                                                </div>
                                                {mainMobileVisible ? (
                                                    <p className="business-owner-text">{index === 0 ? 'Business Owner' : index === 1 ? 'Staff 1' : 'Staff 2'}</p>
                                                ) : (
                                                    <p className="business-owner-text">{index === 0 ? 'Staff 1' : index === 1 ? 'Staff 2' : ''}</p>
                                                )}
                                            </div>
                                            <div hidden={hasSingleNonEmptyValue(merchantdetails.mobile_numbers_enable_on_website)}>
                                                <Divider className="phone-number-divider" />
                                            </div>
                                        </>

                                    ) : ''
                                )
                            })
                        )}
                    </div>
                </DialogContent>
            </Dialog> */}

        </div>
    )
}

export default ProductPage