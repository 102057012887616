import React, { useEffect, useState } from 'react'
import useStyles from '../../../components/Card/styles';
import { Dialog } from '@mui/material';
import { Constants } from '../../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setState } from '../../../redux/reduxStore';
import { ClipLoader } from 'react-spinners';
import { APIConfig } from '../../../utils/apiConfig';
import "./Rewards.css";
import gradientBg from "../../../../assets/rewards-gradient-bg.png";
import blackBanner from "../../../../assets/rewards-black-banner.png";
import rewardsLogo from "../../../../assets/rewards-logo.png";
import close_btn from "../../../../assets/close_btn.png";
import { faChevronLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import ApiService from '../../../utils/ApiService';
import { toast, ToastContainer } from 'react-toastify';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';

const Rewards = ({ shopName, merchantId, loyaltyPoints }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [tncData, setTncData] = useState({});
  const { aboutUsPopupStyle, requestTimedOutError, toastStyle } = Constants;
  const dispatch = useDispatch();
  const loginPage = useSelector((state) => state.counter.loginPage);
  const iframeLoader = useSelector((state) => state.counter.iframeLoader);
  const [userNumber, setUserNumber] = useState(localStorage.getItem("User"));
  const { iframeUrl } = APIConfig;
  const [loyaltyPoint, setLoyaltyPoint] = useState(loyaltyPoints);

  useEffect(() => {
    if (!localStorage.getItem("sessionId")) {
      dispatch(setState({ key: "iframeLoader", value: true }))
      dispatch(setState({ key: "loginPage", value: true }))
    } else {
      getCustomerMerchantLoyaltyInfo();
    }
  }, [])

  useEffect(() => {  // iframe listener
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'storeData') {
        const data = event.data.data;
        if (data) {
          dispatch(setState({ key: "loginPage", value: false }))
          localStorage.setItem("User", data.phoneNumber);
          setUserNumber(data.phoneNumber);
          getCustomerMerchantLoyaltyInfo(data.sessionToken);
          localStorage.setItem("sessionId", data.sessionToken);
          getMerchantDet(data.sessionToken)
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const getMerchantDet = async (sessionId) => {
    const token = sessionId;

    const data = {
      merchantId: merchantId,
      ulatitude: '16.43',
      ulongitude: '74.59'
    }
    try {
      setLoader(true);
      const result = await ApiService.getMerchantDataForCatalogue(data, token);
      if (result?.data?.code == 0) {
        localStorage.setItem("merchantdetails", JSON.stringify(result.data));
        setLoyaltyPoint(result?.data?.merchantDetails?.loyaltyPoints);
      } else {
        alert(result?.data?.message);
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoader(false);
    }
  }

  const getCustomerMerchantLoyaltyInfo = async (sessionId) => { // api to get rewards T&C data
    const token = sessionId || localStorage.getItem('sessionId');
    const data = {
      merchantId: merchantId,
    }
    try {
      setLoader(true);
      const result = await ApiService.getCustomerMerchantLoyaltyInfo(data, token);
      if (result?.code === requestTimedOutError) {
        toast(result.message, { autoClose: 500 });
      } else if (result?.data?.code === 0) {
        setTncData(result?.data?.data);
      } else if (result?.data?.message === 'Invalid Token') {
        localStorage.removeItem("User");
        localStorage.removeItem("sessionId");
        dispatch(setState({ key: "iframeLoader", value: true }))
        dispatch(setState({ key: "loginPage", value: true }))
      } else {
        toast(result.data.message, { autoClose: 500 });
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="mobile-page-container-style">
      <ToastContainer className="toast-container" hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={toastStyle} />
      {loader && (
        <LoadingSpinner />
      )}
      <div className='black-banner-container'>
        <img src={blackBanner} alt='bg' />
        <div className='black-banner-content-container'>
          <div className='rewards-user-container'>
            <FontAwesomeIcon className='back-icon-rewards' onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/')} icon={faChevronLeft} />
            {userNumber && (
              <p className='user-number-text'>{userNumber?.substring(0, 3)}-xxxx-{userNumber?.slice(-3)}</p>
            )}
          </div>
          <img src={rewardsLogo} className='rewards-logo' alt='logo' />
        </div>
      </div>
      <div className='position-relative'>
        <img className='gradient-background-rewards' src={gradientBg} alt='NA' />
        <div className='gradient-content'>
          <div className='points-content'>
            <div onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards/passbook` : '/rewards/passbook')} className='points-dotted-container cursor-pointer'>
              <p className='reward-points-value'>{loyaltyPoint}</p>
              <div className='dotted-line-rewards' />
            </div>
            <p className='point-balance-sub-text'>Your Point Balance</p>
            <p className='point-balance-calc-text'>1 Point = ₹1</p>
          </div>
          <div className='redeem-buttons-container'>
            <button onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/')} className='store-redeem-btn'>
              <p className='m-0 reward-home-btn-text'>
                Earn/Redeem on<br />
                Online Shopping</p>
              <FontAwesomeIcon className='caret-right-arrow' icon={faCaretRight} />
            </button>
            <button onClick={() => history.push({
              pathname: localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards/in-store` : '/rewards/in-store',
              state: { maxPointsToRedeem: tncData?.maxPointsToRedeem }
            })} className='redeem-online-btn'>
              <p className='m-0 reward-home-btn-text'>
                Earn/Redeem<br />
                Through Payment</p>
              <FontAwesomeIcon className='caret-right-arrow' icon={faCaretRight} />
            </button>
          </div>
          <div className='rewards-divider' />
          <p className='rewards-shop-name-sub-header mb-0'>Points Applicable at</p>
          <p className='rewards-shop-name-text'>{shopName}</p>
          <div className='rewards-bill-container'>
            <p className='rewards-tnc-header'>Terms & Conditions</p>
            <ul className='rewards-tnc-text-container'>
              <li className="rewards-tnc-text">You earn {tncData?.applicableLoyaltyPoints} points for every Rs 100 spend</li>
              <li className="rewards-tnc-text">Maximum of {tncData?.maxLoyaltyPoints} points can be earned in one bill</li>
              <li className="rewards-tnc-text">Points can be earned above bill value of Rs {tncData?.minBillAmount}</li>
              <li className="rewards-tnc-text">During redemption, {tncData?.maxPointsToRedeem}% of the bill can be redeemed against points</li>
            </ul>
          </div>
        </div>
      </div>

      <Dialog
        open={loginPage}
        fullScreen
        classes={{
          paper: classes.iframe,
        }}
        PaperProps={{ sx: aboutUsPopupStyle }}
      >
        <img
          src={close_btn}
          className="login-close-btn-iframe"
          onClick={() => {
            dispatch(setState({ key: "loginPage", value: !loginPage }));
            history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/')
          }}
          alt="close"
        />
        {/* <DialogContent> */}
        <div className="iframe-container">
          {iframeLoader && <ClipLoader className="iframe-loader" color="#125d8d" />}
          <iframe className="iframe-style" title="Login" id="iframe-id" src={iframeUrl} onLoad={() => dispatch(setState({ key: "iframeLoader", value: false }))} />
        </div>
        {/* </DialogContent> */}
      </Dialog>
    </div>
  )
}

export default Rewards