import React from "react";
import blue from "../../../assets/images/bluebg.jpg";
import orange from "../../../assets/images/orangebg.jpg";
import img7 from "../../../assets/images/play-store.png";
import "./index.scss";

const VipPlan = () => {
  return (
    <div>
      <div className="agreement">
        <div
          className="con"
          style={{
            backgroundImage: `url(${blue})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "revert",
          }}
        >
          <div style={{ paddingTop: "40px" }} className="col">
            <div className="user">
              <div className="user__heading">
                <h1>Terms And Conditions</h1>
              </div>
            </div>
          </div>
          <div className="user__content1">
            <h6 style={{ fontWeight: "700", color: "black" }}>
              These terms and conditions outline the rules and regulations for
              the use of VIP Subscription Plan.
            </h6>
            <h6 className="header_tag">
              1. Subscription Plans and their Benefits:
            </h6>
            <p className="p-tag">
              You (Merchant) can opt for any of the following subscription
              plans:
              <br />
              a) Monthly
              <br />
              b) Quarterly
              <br />
              c) Annually
              <br />
              <br />
              Subscription Benefits shall be as follows:
              <br />
              Merchant shall be entitled to receive complimentary deliveries, as
              per number of deliveries and included Kms mentioned in the plan,
              from Merchant’s location to the selected destination every month
              depending upon the plan tenure which means inter-alia:
              <br />
              <br />
              I. In the case of an annual plan, the Merchant shall be entitled
              to mentioned number of complimentary deliveries from the
              Merchant’s location every month, till the expiration of 12 months;
              <br />
              <br />
              II. In case of a quarterly plan, the Merchant shall be entitled to
              mentioned number of complimentary deliveries from the Merchant’s
              location till the expiration of 3 months;
              <br />
              <br />
              III. In case of the monthly plan, the Merchant shall be entitled
              to mentioned number of complimentary deliveries from the
              Merchant’s location till the expiration of 1 month.
              <br />
              <br />
              Provided that the provision of complimentary delivery service
              shall be limited to a radius mentioned in the plan, from the
              merchant's location, as measured by the distance calculation
              feature integrated within the ChattyBao application. Subsequent to
              said distance threshold, the standard rack rates for delivery as
              may be described from time to time shall be applied and payable.
              Once this promotional period or the maximum number of
              complimentary deliveries has reached the threshold, the company
              shall begin charging for each subsequent delivery.
              <br />
              <br />
              Provided further that each plan has an expiry date, once the plan
              is expired all the benefits associated with plan shall cease to
              exist till the renewal of plan.
              <br />
              <br />
              The "Full Rack Rate" refers to the regular price or standard rate
              for the delivery service, as may be prescribed from to time on the
              partner portal.
              <br />
              <br />
              The delivery service is available subject to fair usage. A maximum
              of 7.5Kg weight, which may easily fit on the delivery partner
              vehicle, can be carried by the delivery person. The company at its
              sole discretion may reduce or remove the number of free deliveries
              or free distance.
            </p>
            <h6 className="header_tag">
              2. Settlement charges and Government taxes:
            </h6>
            <p className="p-tag">
              Settlement charges and government taxes are applicable on all
              gross payments collected by Merchant through payment facility
              provided by ChattyBao. The charges include deduction of TCS @1%,
              wherever applicable, and ChattyBao shall deposit the same with
              appropriate government authorities, which can be claimed as Input
              GST credit by the merchant.
            </p>
            <h6 className="header_tag">3. Payments:</h6>
            <p className="p-tag">
              By paying for VIP Plan, Merchant gives consent to ChattyBao to
              renew the subscription plan, basis the selected duration of the
              plan.
              <br />
              <br />
              Merchant has the option to make payments for the subscription plan
              either through the UPI link or the UPI Auto Pay feature provided
              on the ChattyBao Merchant App. The UPI link can be accessed
              through the partner app and allows the merchant to make a one-time
              payment using their UPI-enabled bank account. On the other hand,
              the UPI Auto Pay feature enables merchants to set up recurring
              payments for their subscription plans, which will be automatically
              deducted from their linked UPI-enabled bank account on a
              pre-determined schedule. Both payment methods are secure and
              convenient for merchants to use.
              <br />
              <br />
              The payment for renewal of subscription plan can be done by the
              merchant using the ChattyBao Merchant App, or shall be initially
              attempted through the daily settlement of the merchant. If the
              payments due to the Merchant is insufficient to pay the
              subscription charges, in that case, the UPI Auto Pay option shall
              be triggered for the payment of subscription fees. Activation of
              the plan is contingent upon the complete payment of the plan.
              Merchants shall be entitled to subscription benefits till the
              expiry of the plan.
            </p>
            <h6 className="header_tag">4. Non-Payment:</h6>
            <p className="p-tag">
              Chattybao reserves the right to issue a warning to you or
              temporarily/ indefinitely suspend/ terminate your account/listing/
              services on the Platform and/or refuse to provide you with access
              to the Platform in case of non-payment of any fees/dues or
              processing of refunds by you to Chattybao. Chattybao also reserves
              the right to take legal action in case of non-payment of fees/dues
              by you to Chattybao.
            </p>
            <h6 className="header_tag">5. Payment Surcharges:</h6>
            <p className="p-tag">
              i. Merchants shall not charge Customers an additional fee for
              their use of ordinary forms of payment, including electronic
              transfers or credit cards. Such costs should be built into the
              price of the Product. This policy reduces the potential for
              confusion among Customers about the true cost of a Product.
              Further, some forms of payment surcharges, such as credit card
              surcharges, are prohibited by law or under the issuing
              institution's rules and regulations for merchants.
              <br />
              <br />
              ii. Parties expressly agree that any/all
              remittance/settlement-related pay-outs shall be deemed to have
              been accepted by Merchant if Merchant does not furnish a written
              objection specifying the nature of the dispute within ten (10)
              days from the date of transaction.
            </p>
            <h6 className="header_tag">6. Change in bank details:</h6>
            <p className="p-tag">
              In order to avoid any unnecessary charges or penalties on Us, You
              acknowledge to inform Us (in writing), with at least 15 business
              days prior notice, in the event You decide to change Your bank
              details, warehouse address, or communication address. In case You
              fail to update Us on such changes, We shall not be held liable to
              pay any charges or penalties for the same.
            </p>
            <h6 className="header_tag">7. Auto-renewal plan cancellation:</h6>
            <p className="p-tag">
              A merchant may request for a refund for auto-renewed plans, within 15 days of auto-renewal
              <br />
              <br />
              i. A processing charge of Rs 99 will be applicable for plan cancellation
              <br />
              <br />
              ii. Any consumed delivery benefits will be deducted from the refund, at delivery charges as per rack rate
              <br />
              <br />
              iii. Any cashback given in the wallet will be debited
              <br />
              <br />
              iv. Merchant shall switch off auto-renewal from their payments app, to avoid any further renewals
              <br />
              <br />
              v. The refund will be processed within 5-7 working days after raising the request
            </p>
            <h6 className="header_tag">8. Miscellaneous:</h6>
            <p className="p-tag">
              a) The preferred search of the merchant shall be based on internal
              algorithms of ChattyBao.
              <br />
              <br />
              b) The merchant retains the right to terminate the plan by
              contacting the helpdesk as per the information provided on the
              application. However, it should be noted that no refund shall be
              provided for the remaining duration of the plan in the event of
              cancellation during the term.
              <br />
              <br />
              c) Merchant settlements are subject to deductions for settlement
              charges and government taxes, which are calculated on a per-order
              basis. These charges apply to the gross amount collected from the
              customer. A detailed breakdown of the charges can be found in the
              ChattyBao Merchant portal and will be available for the purpose of
              reconciliation.
            </p>
          </div>
        </div>
      </div>
      <div
        className="container1"
        style={{
          backgroundImage: `url(${orange})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "revert",
        }}
      >
        <div className="con-2">
          <div className="con-2__content">
            <h1>Have a Business ?</h1>
            <h2>5 minute Sign up</h2>
            <div className="con-2__link">
              <a
                href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                target="_blank"
                rel="noreferrer"
              >
                <img src={img7} alt="" />
              </a>
              <div className="con-2__link-content">
                <a
                  href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Download Now</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VipPlan;
