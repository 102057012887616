import React from 'react'
import { Switch, Route } from 'react-router-dom';
import Rewards from './RewardsHome/Rewards';
import InStorePage from './InStorePage.js/InStorePage';
import RewardsPayment from './RewardsPayment.js/RewardsPayment';
import { ToastContainer } from 'react-toastify';
import Passbook from './Passbook/Passbook';

const RewardsRoutes = () => {

    const baseUrl = localStorage.getItem("fullurl");
    let shopName = '';
    let merchantId = '';
    let loyaltyPoints = '';
    if (localStorage.getItem("merchantdetails")) {
        const merchantDetails = { data: JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails }
        shopName = merchantDetails.data.shopname;
        merchantId = merchantDetails.data.merchantid;
        loyaltyPoints = merchantDetails.data.loyaltyPoints;
    }



    return (
        <Switch>
            <Route path={baseUrl ? `/${baseUrl}/rewards` : '/rewards'} exact>
                <Rewards shopName={shopName} merchantId={merchantId} loyaltyPoints={loyaltyPoints} />
            </Route>
            <Route path={baseUrl ? `/${baseUrl}/rewards/in-store` : '/rewards/in-store'}>
                <InStorePage shopName={shopName} merchantId={merchantId} />
            </Route>
            <Route path={baseUrl ? `/${baseUrl}/rewards/payment` : '/rewards/payment'}>
                <RewardsPayment shopName={shopName} merchantId={merchantId} />
            </Route>
            <Route path={baseUrl ? `/${baseUrl}/rewards/passbook` : '/rewards/passbook'}>
                <Passbook merchantId={merchantId} />
            </Route>
        </Switch>
    )
}

export default RewardsRoutes