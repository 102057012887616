/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-vars */
import "animate.css/animate.min.css";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useHistory } from "react-router";
// import Fade from 'react-reveal/ScrollAnimation';
import playstore from "../../../assets/images/play-store.png";
import phone_image from "../../../assets/phone_image.png";
import consumer_image1 from "../../../assets/consumer_image1.png";
import consumer_image2 from "../../../assets/consumer_image2.png";
import consumer_image3 from "../../../assets/consumer_image3.png";
import consumer_image4 from "../../../assets/consumer_image4.jpg";
import locationMarker from "../../../assets/location.png";
import Big_box from "../../../assets/Big_box.png";
import lightning_icon from "../../../assets/lightning_icon.png";
import video from "../../../assets/cover_video1.mp4";
import video_vertical from "../../../assets/cover_video_vertical.mp4";
import download_on_appstore from "../../../assets/download_on_appstore.svg";
import "./index.scss";
import { useRef } from 'react';
import { useEffect } from 'react';

const NewHome = () => {
  let history = useHistory();
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.play();
  }, [])

  return (
    <>
      <div className="top_container">
        {/* <img src={temp_image} style={{width: '100%'}} /> */}
        <video className="cover_video" preload="auto" playsInline ref={videoRef} src={window.innerWidth <= 480 ? video_vertical : video} style={{ width: '100%' }} loop autoplay muted />
        <div className="box-whole-container">
          <div className="box_container">
            <img className="big_box" src={Big_box} alt="NA" />
            <div
              style={{
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="box_container_text">
                Your favourite local stores
                <br />
                Now on ChattyBao
              </p>
              <div className="inside_box_container">
                <img src={lightning_icon} className="lightning_image" alt="" />
                <p className="inside_box_text">
                  Order Online, Chat or on Call
                  <br />
                  <span className="bold-text">Delivery in 30 minutes</span>
                </p>
              </div>
              <div className="playstore-appstore-container">
                <a href="https://play.google.com/store/apps/details?id=com.goping.user">
                  <img
                    className="playstore_icon_top"
                    src={playstore}
                    alt="playstore"
                  />
                </a>
                <a href="https://chattybaouser.page.link/RLd2">
                  <img
                    className="whatsapp_icon_top"
                    src={download_on_appstore}
                    alt="playstore"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="location-box-container">
            <img src={locationMarker} className="location-marker-icon" alt="NA" />
            <p className="live-in-location-text">Live in Lucknow & Noida</p>
          </div>
        </div>




      </div>
      <div className="body_container">
        <div className="first_container">
          <div>
            <p className="choosefrom_text" style={{ textAlign: "center" }}>
              Choose from
            </p>
            <div className="count_container">
              <div className="count_div">
                <p className="count_text">5 lac+</p>
                <p className="count_bottom_text">products</p>
              </div>
              <div className="count_div">
                <p className="count_text">45+</p>
                <p className="count_bottom_text">categories</p>
              </div>
              <div>
                <p className="count_text">5000+</p>
                <p className="count_bottom_text">shops</p>
              </div>
            </div>
          </div>
          <img src={phone_image} className="phone_image" alt="NA" />
        </div>
        <div className="second_container">
          <div className="ad_container">
            <img className="ad_image" src={consumer_image1} alt="NA" />
            <div className="adtext_container swap_data">
              <p className="adhead_text">
                Get widest
                <br />
                Variety of Products
              </p>
              <p className="adsub_text">
                Medicines, Patanjali, Stationery, Grocery, Meat, Fruits &
                Vegetables, Puja items, Mobiles, Home and Kitchen needs +
                everything else from any store in your city.
              </p>
            </div>
          </div>
          <div className="ad_container2">
            <div className="adtext_container text_align_end">
              <p className="adhead_text">
                Compare Prices
              </p>
              <p className="adsub_text">
                Compare market prices of any item.<br />Save money
              </p>
            </div>
            <img className="swap_data ad_image" src={consumer_image2} alt="NA" />
          </div>
          <div className="ad_container">
            <img className="ad_image" src={consumer_image3} alt="NA" />
            <div className="adtext_container swap_data">
              <p className="adhead_text">
                Reliable and Quick
                <br />
                Delivery
              </p>
              <p className="adsub_text">
                Get anything delivered in 30 minutes!
              </p>
            </div>
          </div>
          <div className="ad_container2">
            <div className="adtext_container text_align_end">
              <p className="adhead_text">
                Option to use
                <br />
                Chat/ Call / Video
              </p>
              <p className="adsub_text">
                Interact directly with shopkeepers. You can also send a photo
                of your shopping list, send a voice note or video call.
              </p>
            </div>
            <img className="swap_data ad_image" src={consumer_image4} alt="NA" />
          </div>
        </div>
        <div className="third_container">
          <p className="thirdcontainer_text">
            Dukaan ka Bharosa, Online ka Aaram!
          </p>
          <p className="download_text" style={{ textAlign: 'center' }}>Download App now</p>
          <div className="app-download-btns-container">
            <a href="https://play.google.com/store/apps/details?id=com.goping.user">
              <img className="playstore_icon" src={playstore} alt="playstore" />
            </a>
            <a href="https://chattybaouser.page.link/RLd2">
              <img
                className="whatsapp_icon"
                src={download_on_appstore}
                alt="playstore"
              />
            </a>
          </div>
        </div>
        <div className="fourth_container">
          <p className="business_text">Have a business?</p>
          <p className="businesssub_text">
            Partner with ChattyBao to{" "}
            <span className="bold_text">grow your business</span> and reach new
            customers.
          </p>
          <button onClick={() => history.push("business")} className="click_btn">CLICK HERE</button>
        </div>
      </div>
    </>
  );
};

export default NewHome;
