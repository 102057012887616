const Constants = {
    menuBarStyle: {
        width: "90%",
        marginBottom: 0,
        height: '100%',
        marginRight: 'auto',
        // backgroundColor: '#AAD49F'
    },

    chattybaoContactTexts: {
        email: 'help@chattybao.com',
        contactNumber: '8076219166',
        addressline1: 'C-17, Basement, B-3, Guru Nanak Pura,',
        addressline2: 'Laxmi Nagar, New Delhi 110092'
    },

    aboutUsPopupStyle: {
        width: "100%",
        height: '40%',
        marginRight: '10%',
        marginLeft: '10%',
        overflowY: 'hidden'
    },

    websiteThemeColors: {
        black: 'black',
        white: 'white'
    },

    addressPopUpStyle: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "0",
        marginLeft: '0',
        marginRight: "0",
        height: '40%',
        paddingLeft: '10px',
        paddingTop: '10px',
        overflow: 'visible'
    },
    mapContainerStyle: {
        height: '100%',
        width: '100%'
    },
    toastStyle: {
        borderRadius: '20px',
        alignSelf: 'center',
        marginLeft: '3%',
        marginRight: '3%',
        verticalAlign: 'middle'
    },
    userCashbackTermsPage: 'https://webadmin.chattybao.com/offer/usercashback',
    productTncText: 'All images are for representation purpose only. While every effort has been made to maintain accurate and up to date product related content, it is recommended to read product labels, batch and manufacturing details along with warnings, and directions before using or consuming a packaged product. For other products or any additional information, please contact the seller and incase you wish to contact the manufacturer, the address mentioned on the product label should be used.',
    domainNames: ['devwebsite.chattybao.com', 'localhost', 'www.chattybao.com', 'chattybao.com'],
    requestTimedOutError: 'ECONNABORTED',
    subPaths: [
        '/product',
        '/cid',
        '/rewards',
        '/past-orders'
    ],
    rewardsInputFieldStyle: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
                color: 'black',
            },
            '&:hover fieldset': {
                borderColor: 'black',
                color: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
                color: 'black'
            },
        },
    },
    rewardsInputFieldTheme: {
        styleOverrides: {
            root: {
                color: 'black', // Default label color
            },
            shrink: {
                color: 'black !important', // Change label color when focused/shrunk
            },
            '&.MuiFormLabel-filled': {
                transform: 'translate(50px, -50px) scale(0.15)', // Adjust position when filled
            },
            // Adjust the gap when the label is shrunk
            '&.MuiInputLabel-shrink': {
                transform: 'translate(50px, -50px) scale(0.15)', // Adjust position when shrunk
            },
        },
    },
    optionsPopupStyle: {
        margin: "auto 0 0 0",
        width: '100%',
    },

    previousDatesPopUpStyle: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "0",
        marginLeft: '0',
        marginRight: "0",
        minHeight: '30%',
        paddingLeft: '10px',
        paddingTop: '10px',
        overflow: 'visible',
        borderTopRightRadius: '20px',
        borderTopLeftRadius: '20px',
    },

    previousDateTitleStyle: {
        m: 0,
        p: 0,
        marginTop: '30px',
        paddingLeft: '5px',
    }
}

export { Constants };