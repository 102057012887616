import React, { useState, useRef, useEffect } from 'react'
import "./InStorePage.css"
import gradientBg from "../../../../assets/rewards-gradient-bg.png";
import blackBanner from "../../../../assets/rewards-black-banner.png";
import rewardsLogo from "../../../../assets/rewards-logo.png";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { Constants } from '../../../utils/Constants';
import ApiService from '../../../utils/ApiService';
import { ToastContainer, toast } from 'react-toastify';
import { commonFunctions } from '../../../utils/CommonFunctions';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';
import { APIConfig } from '../../../utils/apiConfig';

const theme = createTheme({
  components: {
    MuiInputLabel: Constants.rewardsInputFieldTheme,
  },
});

const InStorePage = ({ shopName, merchantId }) => {

  const history = useHistory();
  const location = useLocation();
  const stateParams = location.state || {};
  const maxPointsToRedeem = stateParams?.maxPointsToRedeem;
  const [showBillDetails, setShowBillDetails] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [billAmount, setBillAmount] = useState('');
  const { rewardsInputFieldStyle, requestTimedOutError, toastStyle } = Constants;
  const payUFormRefRewards = useRef(null);
  const inputRefsRewards = useRef({});
  const [billData, setBillData] = useState({});
  const [loader, setLoader] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [payUPaymentOptions, setPayUPaymentOptions] = useState('');
  const userNumber = localStorage.getItem("User");
  const { currency, disallowMinusDecimal } = commonFunctions;

  useEffect(() => {
    const handlePageRefresh = () => {
      if (!document.hidden) {
        checkOrderId();
      }
    };

    document.addEventListener('visibilitychange', handlePageRefresh); // executes whenever came back to the page
    checkOrderId();

    return () => {
      document.removeEventListener('visibilitychange', handlePageRefresh);
    };
  }, []);

  useEffect(() => {
    if (billAmount === '') {
      setShowBillDetails(false);
    }
  }, [billAmount])

  const checkOrderId = () => {
    const storedAmount = localStorage.getItem("in-store-amount");
    const paidOrderId = localStorage.getItem("in-store-order-id");
    if (paidOrderId !== null) {
      getOrderDetails(paidOrderId, storedAmount);
    } else if (storedAmount !== null) {
      setBillAmount(storedAmount);
      getRedeemablePoints(storedAmount);
      localStorage.removeItem("in-store-amount");
    }
  }

  const getOrderDetails = async (orderId, storedAmount) => {  // for setU payment, need to check for payment status for success page redirection
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchantId,
      orderId: orderId,
    }
    try {
      setLoader(true);
      const result = await ApiService.getLoyaltyOrderDetailsForUser(data, token);
      if (result?.code === requestTimedOutError) {
        toast(result.message, { autoClose: 500 });
      } else if (result?.data?.code === 0) {
        if (result?.data?.data?.paymentStatus === 0) {
          const merDetails = JSON.parse(localStorage.getItem("merchantdetails"));
          merDetails.merchantDetails.loyaltyPoints = result?.data?.data?.loyaltyPoints;
          localStorage.setItem("merchantdetails", JSON.stringify(merDetails));
          history.push({
            pathname: localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards/payment` : '/rewards/payment',
            state: { orderDet: result?.data?.data }
          })
        } else if (storedAmount) {
          setBillAmount(storedAmount);
          getRedeemablePoints(storedAmount);
        }
      } else {
        toast(result.data.message, { autoClose: 500 });
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoader(false);
    }
  }

  const getRedeemablePoints = async (storedAmount) => {
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchantId,
      billAmount: storedAmount || billAmount,
    }
    try {
      setLoader(true);
      const result = await ApiService.getRedeemablePoints(data, token);
      if (result?.code === requestTimedOutError) {
        toast(result.message, { autoClose: 500 });
      } else if (result?.data?.code === 0) {
        const bill = {
          billAmount: result?.data?.data?.billAmount,
          merchantId: result?.data?.data?.merchantId,
          amtToPay: result?.data?.data?.amtToPay,
          redeemablePoints: result?.data?.data?.redeemablePoints,
          pointsEarned: result?.data?.data?.pointsEarned
        }
        setPaymentMethod(result?.data?.data?.paymentMethod);
        setPayUPaymentOptions(result?.data?.data?.payuPaymentMethods);
        setBillData(bill);
        setShowBillDetails(true);
        setBtnDisabled(true);
      } else if (result?.data?.message === "No points to redeem") {
        const bill = {
          billAmount: billAmount,
          merchantId: merchantId,
          amtToPay: billAmount,
          redeemablePoints: 0,
        }
        setBillData(bill);
        setShowBillDetails(true);
        setPaymentMethod(result?.data?.data?.paymentMethod);
        setPayUPaymentOptions(result?.data?.data?.payuPaymentMethods);
        setBtnDisabled(true);
      } else {
        toast(result.data.message, { autoClose: 500 });
        setShowBillDetails(false);
      }
    } catch (error) {
      alert(error.message);
      setShowBillDetails(false);
    } finally {
      setLoader(false);
    }
  }

  const handleFormSubmit = () => {
    if (payUFormRefRewards.current) {
      payUFormRefRewards.current.submit();
    }
  };

  const createPaymentLinkForInStore = async (transactionId) => {
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchantId,
      billAmount: billData?.billAmount,
      transactionId: transactionId || null,
    }
    try {
      setLoader(true);
      const result = await ApiService.createPaymentLinkForInStore(data, token);
      if (result?.code === requestTimedOutError) {
        toast(result.message, { autoClose: 500 });
      } else if (result?.data?.code === 0) {
        const url = result.data.data.paymentLink;
        // history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/')
        localStorage.setItem("in-store-order-id", result.data.data.orderId);
        localStorage.setItem("in-store-amount", billData?.billAmount);
        if (billData?.amtToPay == 0) {
          setTimeout(() => {
            setLoader(false);
            history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards/payment` : '/rewards/payment');
          }, 2000)
        } else if (paymentMethod === 'PAYU') {
          handleFormSubmit();
          setTimeout(() => {
            setLoader(false);
          }, 5000)
        } else {
          window.location.href = url;
          setTimeout(() => {
            setLoader(false);
          }, 5000)
        }
      } else {
        toast(result.data.message, { autoClose: 500 });
      }
    } catch (error) {
      alert(error.message)
      setLoader(false);
    } finally {
    }
  }

  const generateHashcodeForWeb = async () => {
    setLoader(true);
    const data = {
      amount: billData.amtToPay,
      productInfo: "websiteOrder",
      firstName: localStorage.getItem("User"),
      udf1: `${localStorage.getItem("webUrl")}/rewards/payment`,
      udf2: `${localStorage.getItem("webUrl")}/rewards/in-store`
    }
    try {
      const result = await ApiService.generateHashcodeForWeb(data);
      if (result?.data?.code == 0) {
        console.log("hashResult", result)
        if (inputRefsRewards.current.key) inputRefsRewards.current.key.value = result.data.data.payuKey;
        if (inputRefsRewards.current.txnid) inputRefsRewards.current.txnid.value = result.data.data.txnId;
        if (inputRefsRewards.current.productinfo) inputRefsRewards.current.productinfo.value = "websiteOrder";
        if (inputRefsRewards.current.amount) inputRefsRewards.current.amount.value = billData.amtToPay;
        if (inputRefsRewards.current.email) inputRefsRewards.current.email.value = '';
        if (inputRefsRewards.current.firstname) inputRefsRewards.current.firstname.value = localStorage.getItem("User");
        if (inputRefsRewards.current.lastname) inputRefsRewards.current.lastname.value = '';
        if (inputRefsRewards.current.surl) inputRefsRewards.current.surl.value = `${APIConfig.baseUrl}payuWebSuccess`;
        if (inputRefsRewards.current.furl) inputRefsRewards.current.furl.value = `${APIConfig.baseUrl}payuWebFailure`;
        if (inputRefsRewards.current.phone) inputRefsRewards.current.phone.value = '';
        if (inputRefsRewards.current.hash) inputRefsRewards.current.hash.value = result.data.data.hashCode;
        createPaymentLinkForInStore(result.data.data.txnId);
      } else {
        setLoader(false);
      }
    } catch (error) {
      alert(error.message);
      setLoader(false);
    }
  }

  const handlePayment = () => {
    if (paymentMethod === "PAYU" && billData?.amtToPay != 0)
      generateHashcodeForWeb();
    else
      createPaymentLinkForInStore();
  }

  return (
    <div className="mobile-page-container-style">
      <ToastContainer className="toast-container" hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={toastStyle} />
      {loader && (
        <LoadingSpinner />
      )}
      <div className='black-banner-container'>
        <img src={blackBanner} alt='bg' />
        <div className='black-banner-content-container'>
          <div className='rewards-user-container'>
            <FontAwesomeIcon className='back-icon-rewards' onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards` : '/rewards')} icon={faChevronLeft} />
            {userNumber && (
              <p className='user-number-text'>{userNumber?.substring(0, 3)}-xxxx-{userNumber?.slice(-3)}</p>
            )}
          </div>
          <img src={rewardsLogo} className='rewards-logo' alt='logo' />
        </div>
      </div>

      <div className='position-relative'>
        <img className='gradient-background-rewards' src={gradientBg} alt='NA' />
        <div className='gradient-content in-store-content'>
          <p className='in-store-shop-name-text'>
            Paying to <br />
            {shopName}
          </p>
          <div className='in-store-divider' />
          <div className='rewards-bill-input-container'>
            <ThemeProvider theme={theme}>
              <TextField
                size='small'
                variant='outlined'
                id="outlined-basic"
                sx={rewardsInputFieldStyle}
                InputProps={{
                  inputProps: { style: { WebkitAppearance: "none" } },
                  endAdornment: null,
                }}
                type='number'
                className="rewards-bill-amt-input"
                label="Enter Total Bill Amount"
                value={billAmount}
                onChange={(e) => {
                  setBillAmount(e.target.value.replace(/\s|[_]|\W|[.,-]/g, ''));
                  setBtnDisabled(false);
                }}
                onKeyDown={disallowMinusDecimal}
              />
            </ThemeProvider>
            <button disabled={!billAmount || btnDisabled} onClick={() => getRedeemablePoints()} className={`store-redeem-btn ${(!billAmount || btnDisabled) && 'submit-btn-disabled'}`}>Submit</button>
          </div>

          {showBillDetails && (
            <div>

              <div className='rewards-bill-container'>
                <div className='bill-data-container-rewards'>
                  <p className='bill-left-text'>Total Bill Amount:</p>
                  <p className='bill-right-text'>{currency(billData?.billAmount)}</p>
                </div>
                <div className='rewards-bill-divider' />
                <div className='bill-data-container-rewards'>
                  <div>
                    <p className='bill-left-text mb-0 '>Eligible points for redemption:</p>
                    <p className='bill-left-sub-text mt-0 '>Max {maxPointsToRedeem}% of the payment amount<br />can be redeemed</p>
                  </div>
                  <p className='bill-right-text green-text'>- {billData?.redeemablePoints}</p>
                </div>
                <div className='rewards-bill-divider' />
                <div className='bill-data-container-rewards'>
                  <p className='bill-left-text'>Net Amount Payable:</p>
                  <p className='bill-right-text'>{currency(billData?.amtToPay)}</p>
                </div>
              </div>
              <div className='earnings-black-band'>
                <p className="rewards-rupee-icon">₹</p>
                <p className='earnings-text'>You are earning {billData?.pointsEarned} points on this bill</p>
              </div>

            </div>
          )}

          <div className={`rewards-divider ${showBillDetails ? 'in-store-bottom-divider-between' : 'in-store-bottom-divider'}`} />

          <center hidden={!showBillDetails}>
            <button onClick={handlePayment} className='rewards-pay-now-btn'>
              {billData?.amtToPay == 0 ? 'Confirm & Proceed' : `Pay Now ${currency(billData?.amtToPay)}`}
            </button>
          </center>

        </div>
        <form ref={payUFormRefRewards} action='https://secure.payu.in/_payment' method='post'>
          <input type="hidden" name="key" ref={el => inputRefsRewards.current.key = el} />
          <input type="hidden" name="txnid" ref={el => inputRefsRewards.current.txnid = el} />
          <input type="hidden" name="productinfo" ref={el => inputRefsRewards.current.productinfo = el} />
          <input type="hidden" name="amount" ref={el => inputRefsRewards.current.amount = el} />
          <input type="hidden" name="email" ref={el => inputRefsRewards.current.email = el} />
          <input type="hidden" name="firstname" ref={el => inputRefsRewards.current.firstname = el} />
          <input type="hidden" name="lastname" ref={el => inputRefsRewards.current.lastname = el} />
          <input type="hidden" name="surl" ref={el => inputRefsRewards.current.surl = el} />
          <input type="hidden" name="furl" ref={el => inputRefsRewards.current.furl = el} />
          <input type="hidden" name="phone" ref={el => inputRefsRewards.current.phone = el} />
          <input type="hidden" name="hash" ref={el => inputRefsRewards.current.hash = el} />
          <input type="hidden" name="udf1" value={`${localStorage.getItem("webUrl")}/rewards/payment`} />
          <input type="hidden" name="udf2" value={`${localStorage.getItem("webUrl")}/rewards/in-store`} />
          {payUPaymentOptions && (
            <input type="hidden" name="enforce_paymethod" value={payUPaymentOptions} />
          )}
        </form>
      </div>
    </div >
  )
}

export default InStorePage