/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { useHistory } from "react-router";
import chattybao_logo from "../../../assets/chattybao_logo.png";
import menu_toggle from "../../../assets/menu_toggle.png";
import CloseIcon from "../../../assets/CloseIcon.png";
import "./index.scss";
import { Dialog, DialogContent } from "@mui/material";
import useStyles from "../../components/Card/styles";

const Header = () => {
  const history = useHistory();
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [toggleTab, setToggleTab] = useState(1);
  const [menuVisible, setMenuVisible] = useState(false);

  const classes = useStyles();

  // const handleClickScroll = () => {
  //   history.push("/");
  //   window.scrollTo(0, 0);
  // };

  const handleTab = (value) => {
    setToggleTab(value);
    if (value === 1) {
      history.push("/");
      setMenuVisible(false);
    } else if (value === 2) {
      history.push("/business");
      setMenuVisible(false);
    } else if (value === 3) {
      history.push("/about-us");
      setMenuVisible(false);
    } else {
      history.push("/contact-us");
      setMenuVisible(false);
    }

  }

  return (
    <div
      className="header"
      style={{
        backgroundColor: "#125d8d",
        color: "white",
        // position: "relative",
      }}
    >
      {(true) ? (
        <>
          <div
            onClick={() => handleTab(1)}
            className="tabs"
            style={{
              color: `${(history.location.pathname === '/') ? "white" : "#cccccc"}`,
            }}
          >
            <p className="header-text">HOME</p>
            {(history.location.pathname === '/') && <div className="toggle_highlight" />}
          </div>
          <div
            onClick={() => handleTab(2)}
            className="tabs"
            style={{
              color: `${(history.location.pathname === '/business') ? "white" : "#cccccc"}`,
            }}
          >
            <p className="header-text">FOR BUSINESSES</p>
            {(history.location.pathname === '/business') && <div className="toggle_highlight" />}
          </div>
          <div
            onClick={() => handleTab(3)}
            className="tabs"
            style={{
              color: `${(history.location.pathname === '/about-us') ? "white" : "#cccccc"}`,
            }}
          >
            <p className="header-text">ABOUT US</p>
            {(history.location.pathname === '/about-us') && <div className="toggle_highlight" />}
          </div>
        </>
      ) : ''}

      <img
        onClick={() => setMenuVisible(true)}
        src={isTabOpen ? CloseIcon : menu_toggle}
        className="menu_toggle"
        style={{
          width: `${isTabOpen ? "20px" : "40px"}`,
        }}
        alt="menu"
      />
      <img
        src={chattybao_logo}
        className="logo_chattybao"
        alt="logo"
      />
      {history.location.pathname === '/business' && (
        <button onClick={() => window.open('https://forms.gle/myGPMpekd9N1Vndv9')} className="demo-btn">Request a Demo</button>
      )}
      <Dialog
      classes={{ paper: classes.headerTabStyle }}
        onClose={() => setMenuVisible(!menuVisible)}
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
        open={menuVisible}
      >
        <img
          src={CloseIcon}
          className="toggle-close-icon"
          onClick={() => setMenuVisible(false)}
          alt="close"
        />
        <DialogContent className="toggle_data">
          <div onClick={() => handleTab(1)} style={{ width: "100%" }}>
            <p className="menu_text">Home</p>
          </div>
          <div onClick={() => handleTab(2)} style={{ width: "100%" }}>
            <p className="menu_text">For Businesses</p>
          </div>
          <div onClick={() => handleTab(3)} style={{ width: "100%" }}>
            <p className="menu_text">About Us</p>
          </div>
          <div onClick={() => handleTab(4)} style={{ width: "100%" }}>
            <p className="menu_text">Contact Us</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Header;
