import React from 'react'
import './AboutUs.css'
import coFounder1 from '../../../assets/co-founder1.png';
import coFounder2 from '../../../assets/co-founder2.png';
import linkedInIcon from '../../../assets/linked-in-icon.png';

const AboutUs = () => {
  return (
    <div className='about-us-page'>
      <p className='founders-header'>Founders</p>
      <div className='co-founder-whole-container'>

        <div className='co-founder-container'>
          <img src={coFounder1} className='cofounder1-image' alt='NA' />
          <div className='co-founder1-text-container'>
            <p className='co-founder-name'>Kumar Aditya</p>
            <div className="card-topic-divider" />
            <p className='co-founder-tag'>Co-Founder & CEO</p>
            <div onClick={() => window.open('https://www.linkedin.com/in/kumar-aditya-bb988a5/')} className='linkedin-tag-container'>
              <p className='linkedIn-tag'>View LinkedIn</p>
              <img src={linkedInIcon} className='linked-in-icon' alt='NA' />
            </div>
          </div>
        </div>

        <div className='co-founder-container'>
          
          <img src={coFounder2} className='cofounder1-image' alt='NA' />
          <div className='co-founder2-text-container'>
            <p className='co-founder-name'>Varun Gupta</p>
            <div className='co-founder2-divider-container'>
              <div className="card-topic-divider" />
            </div>
            <p className='co-founder-tag'>Co-Founder & COO</p>
            <div onClick={() => window.open('https://www.linkedin.com/in/varung13/')} className='linkedin-tag-container justify-end'>
              <p className='linkedIn-tag'>View LinkedIn</p>
              <img src={linkedInIcon} className='linked-in-icon' alt='NA' />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AboutUs