export const AmplifyConfiguration = {
    aws_project_region: 'ap-south-1',
    aws_cognito_identity_pool_id: 'ap-south-1:e2314877-7e40-4aea-9f74-f8d315cb0c2a',
    aws_cognito_region: 'ap-south-1',
    aws_user_pools_id: 'ap-south-1_qb1CKIRfv',
    aws_user_pools_web_client_id: '79n19gu2jgn68c50gn4g8e9p72',
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [
        "EMAIL"
    ],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [
        "SMS"
    ],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: []
    },
    aws_cognito_verification_mechanisms: [
        "EMAIL"
    ]
} 