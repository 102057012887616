import React from "react";
import { useHistory } from "react-router";
import "./index.scss";
import chattybao_logo from "../../../assets/chattybao_logo.png";
import FB from "../../../assets/FB@2x.png";
import IG from "../../../assets/IG@2x.png";
import TW from "../../../assets/TW@2x.png";
import YT from "../../../assets/YT@2x.png";

const Footer = () => {
  const history = useHistory();

  return (
    <div className="footer">
      <img
        className="chattybao_logo"
        src={chattybao_logo}
        style={{ height: "100px", width: "200px" }}
        alt="logo"
      />
      <div className="policy_container">
        <div className="links_container">
          <div className="left_links">
          <p
              className="footer_text"
              onClick={() => history.push("/about-us")}
              style={{ fontFamily: "Barlow-regular" }}
            >
              About Us
            </p>
            <p
              className="footer_text"
              onClick={() => history.push("/privacy-policy")}
              style={{ fontFamily: "Barlow-regular" }}
            >
              Privacy Policy
            </p>
            <p
              className="footer_text"
              onClick={() => history.push("/user-agreement")}
              style={{ fontFamily: "Barlow-regular" }}
            >
              Terms and Conditions
            </p>
            <p
              className="footer_text"
              onClick={() => history.push("/contact-us")}
              style={{ fontFamily: "Barlow-regular" }}
            >
              Contact Us
            </p>
          </div>

          <div className="right_links">
            <p
              className="footer_text"
              onClick={() => history.push("/Grievance-Redressal")}
              style={{ fontFamily: "Barlow-regular" }}
            >
              Grievance Redressal
            </p>
            <p
              className="footer_text"
              onClick={() => history.push("/CancellationRefundsReturnExchange")}
              style={{ fontFamily: "Barlow-regular" }}
            >
              Cancellation, Refunds, Return and Exchange
            </p>
          </div>
        </div>

        <p
          className="copyright_text"
          style={{
            fontSize: "14px",
            fontWeight: "200",
            overflowWrap: "break-word",
            fontFamily: "Barlow-regular",
          }}
        >
          Copyright © 2023 Chattybao Technologies Private Limited. All Rights
          Reserved.
        </p>
        <div className="bottom_container">
          <div style={{ display: "flex" }}>
            <p
              className="copyright_text"
              style={{
                fontSize: "14px",
                fontWeight: "200",
                overflowWrap: "break-word",
                fontFamily: "Barlow-regular",
                whiteSpace: "nowrap",
              }}
            >
              CIN: U74999DL2021PTC388357,
            </p>
            <p
              className="copyright_text"
              style={{
                fontSize: "14px",
                fontWeight: "200",
                overflowWrap: "break-word",
                fontFamily: "Barlow-regular",
                whiteSpace: "nowrap",
              }}
            >
              GSTIN: 07AAJCC8423B1ZW
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <a href="https://www.facebook.com/chattybao/">
              <img className="social_icon" src={FB} alt="FB" />
            </a>
            <a href="https://twitter.com/chattybao">
              <img
                className="social_icon"
                src={TW}
                style={{ marginLeft: "20px" }}
                alt="TW"
              />
            </a>

            <a href="https://www.instagram.com/chattybao/">
              <img
                className="social_icon"
                src={IG}
                style={{ marginLeft: "20px" }}
                alt="IG"
              />
            </a>

            <a href="https://www.youtube.com/@chattybao1468">
              <img
                className="social_icon"
                src={YT}
                style={{ marginLeft: "20px" }}
                alt="YT"
              />
            </a>
          </div>
        </div>
      </div>
      {/* <div className="footer">
				<div className="footer__container">
					<div className="footer__heading">
						<h5 onClick={() => history.push("/privacy-policy")}>
							Privacy Policy
						</h5>
						<h5 className="footer__line"> |</h5>
						<h5 onClick={() => history.push("/user-agreement")}>
							{" "}
							Terms and Conditions
						</h5>
						<h5 className="footer__line"> |</h5>
						<h5 onClick={() => history.push("/contact-us")}>Contact Us</h5>
					</div>
					<div className="footer__content">
						<h6>
							Registered Office Address: C-17, Basement, B-3, Guru Nanak Pura,
							Laxmi Nagar, New Delhi 110092
						</h6>
						<h6>
							Copyright © 2021 Chattybao Technologies Private Limited - All
							Rights Reserved.
						</h6>
						<h6>CIN: U74999DL2021PTC388357</h6>
						<h6>GSTIN: 07AAJCC8423B1ZW</h6>
					</div>
				</div>
			</div> */}
    </div>
  );
};

export default Footer;
