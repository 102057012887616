import React, { useEffect, useRef, useState } from 'react';
import "./PastOrders.css";
import ApiService from '../../utils/ApiService';
import useStyles from '../../components/Card/styles';
import { Constants } from '../../utils/Constants';
import closeIcon from "../../../assets/close_btn.png";
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setState } from '../../redux/reduxStore';
import { commonFunctions } from '../../utils/CommonFunctions';
import { ClipLoader } from 'react-spinners';
import { APIConfig } from '../../utils/apiConfig';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import callWhiteIcon from '../../../assets/white-call-btn.png';
import callGreenIcon from '../../../assets/call-green.png';
import whatsappGreenIcon from '../../../assets/whatsapp-greenborder.png';
import whatsappWhiteIcon from '../../../assets/homepage-whatsapp.png';
import { useHistory } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";


const PastOrders = () => {
    const [loader, setLoader] = useState(false);
    const [previousDate, setPreviousDate] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [selectedInvoiceData, setSelectedInvoiceData] = useState({});
    const classes = useStyles();
    const { previousDatesPopUpStyle, previousDateTitleStyle, aboutUsPopupStyle } = Constants;
    const { iframeUrl } = APIConfig;
    const dispatch = useDispatch();
    const merchantDetails = JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails;
    const { getMerchantDet } = commonFunctions;
    const loginPage = useSelector((state) => state.counter.loginPage);
    const iframeLoader = useSelector((state) => state.counter.iframeLoader);
    const whatsappOpen = useSelector((state) => state.counter.whatsappOpen);
    const openPhone = useSelector((state) => state.counter.openPhone);
    const history = useHistory();
    const threeTabsRef = useRef(null);

    useEffect(() => {  // checking if page opened from link or catalogue
        localStorage.removeItem("login-flag");
        if (window.location.href.includes("/billno/")) {
            getCustomerInvoice();
        } else {
            if (!(localStorage.getItem("User"))) {  // checking if user is logged in or not
                dispatch(setState({ key: "loginPage", value: true }));
                dispatch(setState({ key: "iframeLoader", value: true }));
            } else {
                getCustomerPastInvoices(localStorage.getItem("User"));
            }
        }
    }, []);

    const getCustomerInvoice = async () => {  // get Invoice from link
        const data = {
            weblink: window.location.href,
        }
        try {
            setLoader(true);
            const result = await ApiService.getCustomerInvoice(data);
            if (result?.data?.code == 0) {
                setSelectedInvoiceData(result?.data?.data);
                setTimeout(() => {
                    scrollToTabs();
                }, 500);
            } else {
                alert(result?.data?.message);
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoader(false);
        }
    }

    const getCustomerPastInvoices = async (userNumber) => {  // get All Invoices
        const data = {
            customerNumber: userNumber,
            mid: merchantDetails?.merchantid,
        }
        try {
            setLoader(true);
            const result = await ApiService.getCustomerPastInvoices(data);
            if (result?.data?.code == 0) {
                setInvoiceData(result?.data?.invoiceData);
                const loginFlag = localStorage.getItem("login-flag");
                if (loginFlag) {
                    console.log("executed login")
                    setPreviousDate(true);
                    localStorage.removeItem("login-flag");
                } else {
                    console.log("executed non login")
                    setSelectedInvoiceData(result?.data?.invoiceData[0]);
                    setTimeout(() => {
                        scrollToTabs();
                    }, 500);
                }
            } else if (result?.data?.message !== "No Data Available") {
                alert(result?.data?.message);
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoader(false);
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    };

    const formatDateWithOrdinal = (dateString) => {
        if (!dateString)
            return "";
        const date = new Date(dateString);

        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th'; // covers 4th-20th
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };

        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getFullYear();

        const ordinalSuffix = getOrdinalSuffix(day);

        return `${day}${ordinalSuffix} ${month}, ${year}`;
    };

    useEffect(() => {  // iframe listener for login
        const handleMessage = async (event) => {
            if (event.data && event.data.type === 'storeData') {
                const data = event.data.data;
                if (data) {
                    dispatch(setState({ key: "loginPage", value: false }))
                    localStorage.setItem("User", data.phoneNumber);
                    localStorage.setItem("sessionId", data.sessionToken);
                    const loggedIn = await getMerchantDet(data.sessionToken, merchantDetails);
                    if (loggedIn)
                        getCustomerPastInvoices(data?.phoneNumber);
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const handleLoad = () => {
        dispatch(setState({ key: "iframeLoader", value: false }))
    };

    const handlePreviousDatesClick = () => {
        if (localStorage.getItem("User")) {
            if (invoiceData?.length === 0) {
                localStorage.setItem("login-flag", 0);
                getCustomerPastInvoices(localStorage.getItem("User"));
            } else {
                setPreviousDate(true);
            }
        } else {
            localStorage.setItem("login-flag", 0);
            dispatch(setState({ key: "loginPage", value: true }));
            dispatch(setState({ key: "iframeLoader", value: true }));
        }
    }

    const scrollToTabs = () => {
        const elementPosition = threeTabsRef.current.getBoundingClientRect().top + window.scrollY; // Get the position of the element
        const offsetPosition = elementPosition - 80; // Subtract the offset
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth' // Smooth scrolling
        });
    }

    return (
        <div className='past-orders-container'>
            {loader && (
                <LoadingSpinner />
            )}
            {/* top tabs */}
            <div ref={threeTabsRef} className="top-buttons-container top-tabs-container-past-orders">
                <div onClick={() => { history.push(`/${localStorage.getItem("fullurl")}`); dispatch(setState({ key: "fromMenu", value: true })); }} className="left-btn-inactive cursor-pointer">Order Online</div>
                <div onClick={() => dispatch(setState({ key: "whatsappOpen", value: true }))} className={whatsappOpen ? "center-btn-active" : "center-btn-inactive cursor-pointer"}>
                    <p className="order-on-whatsapp-text">Order on</p>
                    <img src={whatsappOpen ? whatsappWhiteIcon : whatsappGreenIcon} alt="w/a" className="btn-icons" />
                </div>
                <div onClick={() => dispatch(setState({ key: "openPhone", value: true }))} className={openPhone ? "right-btn-active" : "right-btn-inactive cursor-pointer"}>
                    <p className="order-on-call-text">Order on</p>
                    <img src={openPhone ? callWhiteIcon : callGreenIcon} alt="call" className="btn-icons" />
                </div>
            </div>
            <div className='past-orders-header-container'>
                <FontAwesomeIcon className='history-icon' icon={faClockRotateLeft} />
                <p className='past-orders-header'>Past orders</p>
            </div>
            <div className='bills-container'>
                <div className='bills-tab-container'>
                    <p className='bill-tab bill-tab-selected'>Store Bills</p>
                    <p className='bill-tab'></p>
                </div>
                {selectedInvoiceData?.invoice_url ? (
                    <div className='date-btn-container'>
                        {selectedInvoiceData?.created_at && (
                            <p className='bill-date-text'>{formatDate(selectedInvoiceData?.created_at)}</p>
                        )}
                        <p onClick={handlePreviousDatesClick} className='previous-dates-text'>Previous Dates</p>
                    </div>
                ) : (
                    <p className='no-data-text'>No Data</p>
                )}

                {selectedInvoiceData?.invoice_url && (
                    <TransformWrapper>
                      <TransformComponent>
                      <img className='bill-image' src={selectedInvoiceData?.invoice_url} alt='bill' />
                      </TransformComponent>
                      </TransformWrapper>
                )}

            </div>

            {/* Previous Date Data Pop Up */}
            <Dialog
                open={previousDate}
                classes={{
                    paper: classes.bigscreens,
                }}
                PaperProps={{ sx: previousDatesPopUpStyle }} onClose={() => setPreviousDate(false)}
            >
                <img
                    src={closeIcon}
                    className='previous-dates-pop-up-close'
                    onClick={() => {
                        setPreviousDate(false);
                    }}
                    alt="close"
                />
                <DialogTitle sx={previousDateTitleStyle}>
                    <p className="select_address_title">Previous Date Bills</p>
                </DialogTitle>

                <DialogContent className="dialog_content_grid2 previous-date-pop-up-content">
                    <div>
                        {invoiceData.length !== 0 && invoiceData.map(data => (
                            <>
                                <p onClick={() => { setSelectedInvoiceData(data); setPreviousDate(false); }} className='date-data'>{formatDateWithOrdinal(data?.created_at)}</p>
                                <div className="cart_divider" />
                            </>
                        ))}
                    </div>
                </DialogContent>
            </Dialog>

            {/* iframe login Pop Up */}
            <Dialog
                open={loginPage}
                fullScreen
                classes={{
                    paper: classes.iframe,
                }}
                PaperProps={{ sx: aboutUsPopupStyle }}
            >
                <img
                    src={closeIcon}
                    className="login-close-btn-iframe"
                    onClick={() => {
                        dispatch(setState({ key: "loginPage", value: !loginPage }));
                        if (!localStorage.getItem("login-flag"))
                            history.push(`/${localStorage.getItem("fullurl")}`);
                    }}
                    alt="close"
                />
                <div className="iframe-container">
                    {iframeLoader && <ClipLoader className="iframe-loader" color="#125d8d" />}
                    <iframe className="iframe-style" title="Login" id="iframe-id" src={iframeUrl} onLoad={handleLoad} />
                </div>
            </Dialog>

        </div>
    )
}

export default PastOrders
