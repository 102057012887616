import React, { useEffect, useState } from 'react'
import "./RewardsPayment.css"
import gradientBg from "../../../../assets/rewards-gradient-bg.png";
import blackBanner from "../../../../assets/rewards-black-banner.png";
import flowerCheck from "../../../../assets/flower_check_fill.png";
import whatsAppIcon from "../../../../assets/whatapp-icon-yellow.png";
import callIcon from "../../../../assets/call-icon-yellow.png";
import close_btn from "../../../../assets/close_btn.png";
import whatsappGreen from "../../../../assets/whatsapp-greenborder.png";
import call_btn from "../../../../assets/call_btn.png";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import { Dialog, DialogContent, Divider } from '@mui/material';
import useStyles from '../../../components/Card/styles';
import { ToastContainer, toast } from 'react-toastify';
import { commonFunctions } from '../../../utils/CommonFunctions';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';
import ApiService from '../../../utils/ApiService';
import { Constants } from '../../../utils/Constants';

const RewardsPayment = ({ shopName, merchantId }) => {

  const history = useHistory();
  const location = useLocation();
  const stateParams = location.state || {};

  // Access your parameters like this:
  console.log("VAL", stateParams.paramName1)
  const orderDet = stateParams?.orderDet;
  const classes = useStyles();
  const [whatsappOpen, setWhatsappOpen] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [orderDetails, setOrderDetails] = useState(orderDet || {});
  const [loader, setLoader] = useState(false);
  const [helpNumber, setHelpNumber] = useState('');
  const merchantdetails = {
    data: JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails
  }
  const merDetails = JSON.parse(localStorage.getItem("merchantdetails"));
  const { currency } = commonFunctions;
  const { requestTimedOutError, toastStyle } = Constants;
  const [orderId] = useState(localStorage.getItem("in-store-order-id"));
  const userNumber = localStorage.getItem("User");

  let mainMobileVisible = true;

  const indexToSwap = merchantdetails.data.mobile_numbers_enable_on_website.indexOf(merchantdetails.data.mainmobilenumber);

  // Check if the valueToCheck is in the array
  if (indexToSwap !== -1) {
    // Make a copy of the array to avoid directly mutating the state
    const newArray = [...merchantdetails.data.mobile_numbers_enable_on_website];

    // Swap the first element with the element containing the valueToCheck
    const temp = newArray[0];
    newArray[0] = newArray[indexToSwap];
    newArray[indexToSwap] = temp;
    merchantdetails.data.mobile_numbers_enable_on_website = newArray

    // Update the state with the new array
  } else {
    mainMobileVisible = false
  }

  function hasSingleNonEmptyValue(arr) {
    const nonEmptyValues = arr.filter(value => value !== '');
    return nonEmptyValues.length === 1;
  }

  const handleWhatsapp = (phoneNumber) => {
    window.open(
      `https://api.whatsapp.com/send?phone=91${phoneNumber}&text=Enquiry from customer`
    );
  };

  const getOrderDetails = async (orderId) => {
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchantId,
      orderId: orderId,
    }
    try {
      setLoader(true);
      const result = await ApiService.getLoyaltyOrderDetailsForUser(data, token);
      if (result?.code === requestTimedOutError) {
        toast(result.message, { autoClose: 500 });
      } else if (result?.data?.code === 0) {
        setOrderDetails(result?.data?.data);
        merDetails.merchantDetails.loyaltyPoints = result?.data?.data?.loyaltyPoints;
        localStorage.setItem("merchantdetails", JSON.stringify(merDetails));
      } else {
        toast(result.data.message, { autoClose: 500 });
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoader(false);
      localStorage.removeItem("in-store-order-id");
      localStorage.removeItem("in-store-amount");
    }
  }

  useEffect(() => {
    getHelpDeskNumber();
    if (orderId !== null && !orderDet) {
      getOrderDetails(orderId);
    } else if (!orderDet && !orderId) {
      history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards` : '/rewards');
    } else {
      localStorage.removeItem("in-store-order-id");
      localStorage.removeItem("in-store-amount");
    }
  }, []);

  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const day = new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(date);
      const month = new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(date);
      const year = new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(date);
      return `${day} ${month}, ${year}`;
    } else {
      return dateString;
    }

  };

  const getHelpDeskNumber = async () => {
    const token = localStorage.getItem('sessionId');
    try {
      const result = await ApiService.getHelpDeskNumber(token);
      console.log("res", result)
      if (result?.code === requestTimedOutError) {
        toast(result.message, { autoClose: 500 });
      } else if (result?.data?.Status === "Success") {
        setHelpNumber(result?.data?.userapp_help_desk)
      } else {
        toast(result.data.message, { autoClose: 500 });
      }
    } catch (error) {
      alert(error.message)
    }
  }

  return (
    <div className="mobile-page-container-style">
      {console.log("Help", helpNumber)}
      <ToastContainer className="toast-container" hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={toastStyle} />
      {loader && (
        <LoadingSpinner />
      )}

      <div className='black-banner-container'>
        <img src={blackBanner} alt='bg' />
        <div className='black-banner-content-container'>
          <div className='rewards-user-container'>
            <FontAwesomeIcon className='back-icon-rewards' onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/')} icon={faChevronLeft} />
            <div>
              <p className='rewards-order-id-text'>Order ID #{orderId}</p>
              <p className='rewards-order-date-text'>{formatDate(orderDetails?.createdDate)}</p>
            </div>
          </div>
          <div className='rewards-call-whatsapp-container'>
            <img onClick={() => setOpenPhone(true)} src={callIcon} className="top-bar-right-icons" alt="whatsapp" />
            <img onClick={() => setWhatsappOpen(true)} src={whatsAppIcon} className="top-bar-right-icons" alt="call" />
          </div>
        </div>
      </div>

      <div className='position-relative'>
        <img className='gradient-background-rewards' src={gradientBg} alt='NA' />
        <div className='gradient-content in-store-content'>

          <div className='rewards-payment-top-box'>
            <p className='top-box-text m-0'>In Store Payment</p>
            <p className='top-box-text m-0'>Mobile xxxxxx{userNumber?.slice(-4)}</p>
          </div>

          <div className='rewards-payment-page-content'>
            <img src={flowerCheck} className='flower-check-rewards' alt='check' />
            <p className='rewards-payment-amount'>{currency(orderDetails?.totalAmount)}</p>
            <p className='bill-cleared-text'>Bill Cleared</p>
            <div className='rewards-payment-page-divider' />
            <p className='paid-by-you-text'>{currency(orderDetails?.amountPaid)} paid by you</p>
          </div>

          <div className='earnings-black-band'>
            <p className="rewards-rupee-icon">₹</p>
            <p className='earnings-text m-0'>
              You have earned {orderDetails?.pointsEarned} points on your bill<br />
              Updated Points Balance: <span className='black-band-points-value'>{orderDetails?.loyaltyPoints}</span>
            </p>
          </div>

          <div className='rewards-bill-container'>
            <div className='bill-data-container-rewards'>
              <p className='bill-left-text'>
                Paid to<br />
                {shopName}:
              </p>
            </div>
            <div className='rewards-bill-divider' />
            <div className='bill-data-container-rewards'>
              <p className='bill-left-text'>Total Bill Amount:</p>
              <p className='bill-right-text'>{currency(orderDetails?.totalAmount)}</p>
            </div>
            <div className='rewards-bill-divider' />
            <div className='bill-data-container-rewards'>
              <p className='bill-left-text green-text'>Points Redeemed:</p>
              <p className='bill-right-text green-text'>-{orderDetails?.pointsRedeemed}</p>
            </div>
            <div className='rewards-bill-divider' />
            <div className='bill-data-container-rewards'>
              <p className='bill-left-text'>Amount paid by you:</p>
              <p className='bill-right-text'>{currency(orderDetails?.amountPaid)}</p>
            </div>
          </div>

          <div className="rewards-divider in-store-bottom-divider-between" />

          <div className='rewards-help-btn-container'>
            <a href={`tel:+91${helpNumber}`} className='rewards-help-btn'>Help?</a>
          </div>
        </div>

      </div>

      <Dialog classes={{ paper: classes.botm_dialog }} open={openPhone || whatsappOpen} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => { setWhatsappOpen(false); setOpenPhone(false); }}>
        <img
          src={close_btn}
          className="call_close_btn"
          onClick={() => {
            setWhatsappOpen(false);
            setOpenPhone(false);
          }}
          alt="close"
        />
        <p className="call-contact-us-header">
          {whatsappOpen ? 'WhatsApp' : openPhone ? 'Contact Us' : ''}
        </p>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
          <div className="call-contact-container">
            {(!merchantdetails.data.mobile_numbers_enable_on_website || merchantdetails.data.mobile_numbers_enable_on_website.length === 0) && (
              <p className="no-contact-number-text">Business has not provided any contact number</p>
            )}
            {merchantdetails.data.mobile_numbers_enable_on_website && merchantdetails.data.mobile_numbers_enable_on_website.length !== 0 && (
              merchantdetails.data.mobile_numbers_enable_on_website.map((data, index) => {
                return (
                  data ? (
                    <>
                      <div onClick={whatsappOpen ? () => handleWhatsapp(data) : () => window.location.href = `tel:+91${data}`} className="phone-owner-container">
                        <div style={{ width: '180px' }}>
                          {whatsappOpen ? (
                            <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                              <img src={whatsappGreen} style={{ height: '20px', width: '20px', marginRight: '10px' }} alt='call' />
                              <p className="phone_call_text phone-number-text">{data}</p>
                            </div>
                          ) : (
                            <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                              <img src={call_btn} style={{ height: '20px', width: '20px', marginRight: '10px' }} alt='call' />
                              <p className="phone_call_text phone-number-text">{data}</p>
                            </div>
                          )}

                        </div>
                        {mainMobileVisible ? (
                          <p className="business-owner-text">{index === 0 ? 'Business Owner' : index === 1 ? 'Staff 1' : 'Staff 2'}</p>
                        ) : (
                          <p className="business-owner-text">{index === 0 ? 'Staff 1' : index === 1 ? 'Staff 2' : ''}</p>
                        )}
                      </div>
                      <div hidden={hasSingleNonEmptyValue(merchantdetails.data.mobile_numbers_enable_on_website)}>
                        <Divider className="phone-number-divider" />
                      </div>
                    </>

                  ) : ''
                )
              })
            )}
          </div>
        </DialogContent>
      </Dialog>

    </div>
  )
}

export default RewardsPayment