import React from "react";
import "./index.scss";
import { Constants } from "../../utils/Constants";

const ContactUs = () => {
  const {chattybaoContactTexts} = Constants
  return (
    <div className="contactus_container">
		<p className="contactus_header" style={{ textAlign: "center" }}>
        Contact Us
      </p>
      <div className="contactus_subcontainer">
        <div style={{ marginBottom: "30px" }}>
          <p className="sub_header text_font_bold">Email: <span className="text_font_regular" style={{color: 'grey'}}>{chattybaoContactTexts.email}</span></p>
          <p className="sub_header text_font_bold">Contact: <span className="text_font_regular" style={{color: 'grey'}}>{chattybaoContactTexts.contactNumber}</span></p>
        </div>
        <div style={{}}>
          <p className="text_font_bold">Registered Office Address</p>
          <p className="text_font_regular" style={{ color: "grey" }}>
            {chattybaoContactTexts.addressline1}
            <br />
            {chattybaoContactTexts.addressline2}
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default ContactUs;
