/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import blue from "../../../assets/images/bluebg.jpg";
import orange from "../../../assets/images/orangebg.jpg";
import img7 from "../../../assets/images/play-store.png";
import "./index.scss";

const UserAgreement = () => {
	return (
		<div>
			<div className="agreement">
				<div
					className="con"
					style={{
						backgroundImage: `url(${blue})`,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						backgroundAttachment: "fixed",
						backgroundPosition: "revert",
					}}
				>
					<div style={{ paddingTop: "40px" }} className="col">
						<div className="user">
							<div className="user__heading">
								<h1>Terms And Conditions</h1>
							</div>
						</div>
					</div>
					<div className="user__content1">
						<p>
							This document is an electronic record in terms of the Information
							Technology Act, 2000 (“IT Act”) and rules made thereunder, as
							applicable, and the amended provisions pertaining to electronic
							records in various statutes, as amended from time to time. This
							document is generated by a computer system and does not require
							any physical signatures.
							<br />
							For the purpose of this User Agreement (hereinafter, the
							Agreement) wherever the context so requires, “You”, “Your”,
							“Yours” or "Customer" shall mean any natural or legal person who
							has agreed to become a user of the Platform by accessing or using
							the Platform. If You are using the Services (as defined below) or
							the Platform on behalf of a company, organization, government, or
							other legal entity, You represent and warrant that You are
							authorized to do so.
							<br />
							Unless the context otherwise requires, “Chattybao Technologies
							Private Limited’’, “We”, “us”, “our” or “Company” shall mean
							Chattybao Technologies Private Limited or any of its licensees,
							whether now or in the future.
							<br />
							The Company enables transactions on its Platform between
							participating shops/stores/merchants/sellers and buyers, dealing
							in consumer goods, consumables, etc., and any other products or
							services ("Platform Services''). The customer ("Customers'') can
							choose and place orders ("Orders'') from a variety of products and
							services listed and offered for sale by various merchants
							including but not limited to the shops/stores, and other service
							providers ("Merchants''), on the Platform.
							<br />
							Use of the Platform and the Services provided are subject to the
							rules and regulations, policies, notices, terms, and conditions
							set forth or mentioned on the website www.chattybao.com
							(“Website”), the mobile application available on the Play Store
							and app store- Chattybao i.e. (“App”) and the uniform resource
							locator created for each Merchant (the ‘Website’, the ‘App’ is
							collectively hereinafter referred to as the “Platform”), operated
							by Chattybao Technologies Private Limited, a company incorporated
							under the laws of India and its registered office at C-17,
							Basement, B-3, Guru Nanak Pura, Laxmi Nagar, Delhi-110092
							<br />
							As a User, the User Agreement shall be effective and binding upon
							your ‘acceptance’. ‘Acceptance’ shall mean Your affirmative action
							is on entering information as requested on the sign-up page or
							simply by accessing or visiting the Platform. If You do not agree
							or are not willing to be bound by the User Agreement and our
							Policies (published on the website), please do not enter
							information as requested on the sign-up page and click the
							“Accept” button, or do not seek to obtain access to, view, visit,
							download or otherwise use the Platform (or any of its
							components/constituents) or any information or Services.
							<br />
							By impliedly or expressly accepting these Terms, You also accept
							and agree to be bound by applicable policies of the Company
							including the Privacy Policy as published on the Platform
							(“Policies”) communicated to the Users by publication on the
							Platform.
							<br />
							The content on the Platform is provided for general information
							only. It is not intended to amount to advice on which you should
							rely. You must obtain professional or specialist advice before
							taking, or refraining from taking, any action on the basis of the
							content on the Platform.
							<br />
							Chattybao and Customer shall collectively be referred to as
							“Parties” and individually as “Party”
						</p>
						<h6>1. DEFENITION</h6>
						<p>
							Whenever used in this Agreement with initial letters capitalized,
							the following terms will have the following specified meaning;
							<br />
							a) “Affiliate” shall mean any Person who directly or indirectly,
							controls, is controlled by, or is under the common control of the
							Company. The term “Control” is used in the sense of the possession
							by a person or a group of persons acting in concert, directly or
							indirectly, of the right to direct or cause the direction of the
							management and policies of another Person, whether through the
							board of directors or ownership of voting rights by such other
							Person, by the Articles of Association, contract or otherwise. A
							Person or a group of persons acting in concert shall be deemed to
							be in control of a body corporate if such Person or group of
							Persons is in a position to appoint or appoints the majority of
							the directors of such body corporate.
							<br />
							b) “Authority” shall mean any union, national, state, local, or
							other governmental, statutory, administrative, judicial,
							regulatory, or self-regulating authority, agency, or
							instrumentality having jurisdiction over the relevant matter.
							<br />
							c) “Grievance Redressal Officer” means the grievance redressal
							officer appointed by the Company in accordance with applicable
							law, from time to time.
							<br />
							d) “Information” means and shall include any confidential and/or
							personally identifiable information or other information provided
							to the Company or other Users of the Platform or at the time of
							registration with the Platform, or through any email/messaging
							feature and shall include without limitation Your name, sex, age,
							email address, mailing address, phone number (if provided) or such
							other personal information.
							<br />
							e) “Internal Service Provider” shall mean logistics or any back
							end service providers of the Platform appointed by the Company
							that will provide various services that the Company may require in
							order to run operations of the Platform for example to facilitate
							or outsource one or more aspects of the business, product and
							service operations provided on the Platform, including search
							technology, discussion boards, payments, affiliate and rewards
							programs, co-branded credit cards, maintenance services, database
							management, etc.
							<br />
							f) “Law” shall mean all statutes, enactments, acts of legislature,
							laws, ordinances, rules, byelaws, regulations, notifications,
							guidelines, policies, directions, directives, and orders of any
							government and or any Authority.
							<br />
							g) “Losses” shall include, without limitation, losses,
							liabilities, actions, suits, claims, proceedings, costs, damages,
							penalties, judgments, amounts paid in settlement, expenses, etc.
							<br />
							h) “Pay Facility” means the automated electronic payment or
							collection and remittance facility provided by the Company to the
							Buyers to facilitate payments for paid services on the Platform
							directly through banks or financial institution infrastructure or
							indirectly through payment gateway facility providers or through
							any such facility authorized by the Reserve Bank of India to
							provide enabling support facility for collection and remittance of
							payment.
							<br />
							i) “Person” shall mean and include any individual, legal entity,
							company, body corporate, partnership firm, association, Hindu
							undivided family, trust, society, limited liability partnership,
							or proprietorship, whether incorporated or not.
							<br />
							j) “Policies” shall mean and include the Privacy Policy and any
							other policies of the Company as amended and provided on the
							Platform or communicated to the Users in any other way from time
							to time.
							<br />
							k) “Services” shall mean the services rendered by the Platform as
							may be specifically notified by the Company on the Platform or by
							other means of communication from time to time, in accordance with
							the applicable law, and currently includes acting as an
							intermediary or a facilitator for providing a platform web and/or
							mobile application for enabling the users to sell their products
							or services online.
							<br />
							l) “User(s)” shall mean a user of the Platform.
						</p>
						<h6>2. INTELLECTUAL PROPERTY</h6>
						<p>
							a) We are either the owner of intellectual property rights or have
							the non-exclusive, worldwide, perpetual, irrevocable,
							royalty-free, sub-licensable (through multiple tiers) right to
							exercise the intellectual property, in the Platform, and in the
							material published on it including but not limited to the user
							interface, layout format, order placing process flow, ideas,
							design, structure, expression, and any content thereof (except any
							content that is posted by the Merchants).
							<br />
							b) You recognize that the Company is the registered owner of the
							word mark ‘ChattyBao’ and the logo including but not limited to
							its variants (“IPR”) and You shall not directly or indirectly,
							attack or assist another in attacking the validity of, or
							Company’s or its Affiliates proprietary rights in, the licensed
							marks or any registrations thereof, or file any applications for
							the registration of the licensed marks or any names or logos
							derived from or confusingly similar to the licensed marks, any
							variation thereof, or any translation or transliteration thereof
							in another language, in respect of any products/services and in
							any territory throughout the world. If You become aware or acquire
							knowledge of any infringement of IPR You shall report the same at
							admin@chattybao.com with all relevant information.
							<br />
							c) You may print off one copy and may download extracts, of any
							page(s) from the Platform for Your personal reference and You may
							draw the attention of others within Your organization to material
							available on the Platform.
							<br />
							d) You must not modify the paper or digital copies of any
							materials You have printed off or downloaded in any way, and You
							must not use any illustrations, photographs, video or audio
							sequences, or any graphics separately from any accompanying text.
							e) You must not use any part of the materials on the Platform for
							commercial purposes without obtaining a license to do so from us
							or our licensors.
							<br />
							f) If You print off, copy or download any part of the Platform in
							breach of these Terms, Your right to use the Platform will cease
							immediately and You must, at our option, return or destroy any
							copies of the materials You have made.
							<br />
							g) You may download information presented on the Platform at Your
							sole risk, and without any express or implied warranty from us in
							relation to such information, provided that: i. such information
							is used solely for personal purposes and not for any commercial
							purposes whatsoever;
							<br />
							ii. no alterations or modifications of any kind are made to such
							information; iii. no further statements and warranties shall be
							made with regard to such information or documents containing such
							information for or on our behalf;
							<br />
							iv. no statements and/or notifications indicating our ownership of
							and other legal rights and interests in relation to such
							information shall be removed, in part or in full; v. such
							information is not reproduced, republished, transmitted,
							displayed, posted or distributed in any form or by any means.
						</p>
						<h6>3. THIRD-PARTY LINKS</h6>
						<p>
							To the extent that the Platform contains links to advertisement
							banners, information about outside services, and resources offered
							by third parties, we do not give any express or implied warranties
							or guarantees regarding the content, services, and information
							provided on such linked websites. Any concerns regarding any such
							services or resources, or any link thereto, should be directed to
							the particular service or resource provider. We provide these
							links only for the convenience of visitors to the Platform. You
							can visit any and all of the linked websites entirely at your own
							discretion, however, we shall assume no responsibility for the
							outcome of any such interactions.
						</p>
						<h6>4. DISCLAIMERS</h6>
						<p>
							a) The Platform may be under constant upgrades, and some functions
							and features may not be fully operational.
							<br />
							b) Due to the vagaries that can occur in the electronic
							distribution of information and due to the limitations inherent in
							providing information obtained from multiple sources, there may be
							delays, omissions, or inaccuracies in the content provided on the
							platform or delay or errors in functionality of the Platform. As a
							result, we do not represent that the information posted is correct
							in every case.
							<br />
							c) We expressly disclaim all liabilities that may arise as a
							consequence of any unauthorized use of credit/ debit cards.
							<br />
							d) The Company disclaims all liability that may arise due to any
							violation of any applicable laws including the Law applicable to
							products and services offered by the Merchant.
							<br />
							e) While the materials provided on the Platform were prepared to
							provide accurate information regarding the subject discussed, the
							information contained in these materials is being made available
							with the understanding that we make no guarantees,
							representations, or warranties whatsoever, whether expressed or
							implied, with respect to professional qualifications, expertise,
							quality of work or other information herein. Further, we do not,
							in any way, endorse any service offered or described herein. In no
							event shall we be liable to You or any third party for any
							decision made or action taken in reliance on such information.
							<br />
							f) The information provided hereunder is provided "as is". We
							and/or our employees make no warranty or representation regarding
							the timeliness, content, sequence, accuracy, effectiveness, or
							completeness of any information or data furnished hereunder or
							that the information or data provided hereunder may be relied
							upon. Multiple responses may usually be made available from
							different sources and it is left to the judgment of Users based on
							their specific circumstances to use, adapt, modify or alter
							suggestions or use them in conjunction with any other sources they
							may have, thereby absolving us as well as our consultants,
							business associates, Affiliates, business partners and employees
							from any kind of professional liability.
							<br />
							g) We shall not be liable to You or anyone else for any losses or
							injury arising out of or relating to the information provided on
							the Platform. In no event will we or our Personnel be liable to
							You or any third party for any decision made or action taken by
							Your reliance on the content contained on the Platform.
							<br />
							h) In no event will we be liable for any damages (including,
							without limitation, direct, indirect, incidental, special,
							consequential, or exemplary damages, damages arising from personal
							injury/wrongful death, and damages resulting from lost profits,
							lost data or business interruption), resulting from any services
							provided by any third party or merchant accessed through the
							platform, whether based on warranty, contract, tort, or any other
							legal theory and whether or not we are advised of the possibility
							of such damages.
							<br />
							i) We reserve the right to do any of the following, at any time,
							without notice: (a) to modify, suspend or terminate operation of
							or access to the Platform, or any portion of the Platform, for any
							reason whatsoever; (b) to modify or change the Platform, or any
							portion of the Platform, and any applicable policies or terms; or
							(c) to interrupt the operation of the Platform, or any portion of
							the Platform, as necessary to perform routine or nonroutine
							maintenance, error correction, or other changes.
						</p>
						<h6>5. NO ENDORSEMENT</h6>
						<p>
							We neither endorse any Merchant nor the products/services offered
							by them. In addition, You are required to provide accurate
							information, we do not attempt to confirm, and do not confirm its
							purported identity. We will not be responsible for any damage or
							harm resulting from Your interactions with Merchants. By using the
							Services, You agree that any legal remedy or liability that You
							seek to obtain for actions or omissions of Merchants or other
							third parties will be limited to a claim against the particular
							Merchant or other third parties who caused You harm and You agree
							not to attempt to impose liability on or seek any legal remedy
							from us with respect to such actions or omissions.
						</p>
						<h6>6. ELIGIBILITY</h6>
						<p>
							a) In order to use the Platform, You need to be 18 (eighteen)
							years of age or older. The Platform may only be used or accessed
							by such persons who can enter into and perform legally binding
							contracts under Indian Contract Act, 1872.
							<br />
							b) The Company shall not be liable in case of any false
							information is provided by the User including the User’s age and
							the User and/or his natural or appointed guardian alone shall be
							liable for the consequences as per the applicable Law of the
							country to which the User belongs.
							<br />
							c) The Company disclaims all liability arising out of such
							unauthorized use of the Platform and any third-party liability
							arising out of Your use of the Platform if You are a minor.
						</p>
						<h6>7. USER INFORMATION</h6>
						<p>
							a) You agree to provide true, accurate, up-to-date, and complete
							information while signing up on the Platform or for any other
							purpose when prompted or requested to do so on the Platform.
							<br />
							b) Certain information You provide on the Platform in Your profile
							may reveal, or allow others to identify different aspects of Your
							private life, and more generally about You. You are expressly and
							voluntarily accepting the terms of the User Agreement and
							supplying all such information by You on the Platform, including
							all information deemed "personal" or “sensitive” by applicable
							Laws, is entirely voluntary on Your part.
							<br />
							c) For the use of our Services, You will be required to use
							certain devices, software, and third-party applications including
							but not limited to WhatsApp, and data connections, which we
							otherwise do not supply. For as long as You use our Services, You
							consent to downloading and installing updates to our Services,
							including automatically, downloading and installing such updates,
							and shall be bound by the terms & conditions of the respective
							third-party application/s.
							<br />
							d) You are responsible for all carrier data plans and other fees
							and taxes associated with Your use of our Services.
							<br />
							e) The Company shall not be responsible in any manner whatsoever
							for the authenticity of the personal information or sensitive
							personal data or information supplied by the User to the Company
							or to any other person acting on behalf of the Company.
							<br />
							f) You are prohibited from misrepresenting Your identity and agree
							not to represent Yourself as another User or login/ register using
							the identity of any other person. You are responsible to maintain
							and promptly update the information provided while signing up or
							verifying or for any other purpose on the Platform to ensure that
							the information provided by You is true, accurate, current,
							complete, and not misleading at all times.
							<br />
							g) If You provide any information that is untrue, inaccurate,
							misleading, not current or incomplete or the Company has
							reasonable grounds to believe that such information is untrue,
							inaccurate, misleading, not current or incomplete, or not in
							accordance with the User Agreement, the Company reserves the right
							to indefinitely block Your use or access to the Platform in any
							manner whatsoever. Should any other User or Person act upon such
							untrue, inaccurate, not current, or incomplete information
							provided or verified by You, the Company, and its Personnel shall
							not be liable for any damages, losses, direct, indirect, immediate
							or remote, interests or claims resulting from such information to
							You or to any third party. You hereby agree and undertake to
							indemnify and to hold harmless the Company, and its personnel in
							accordance with the Indemnity clause contained in these Terms.
							<br />
							h) Once You have signed up on the Platform, You agree, allow and
							grant the Platform to gain access to Your mobile device to find
							and keep track of mobile phone numbers of other Users of the
							Service, Your location, inbuilt storage or other storage on Your
							mobile device, access to the internet, control vibration, accounts
							on Your mobile device and such other data or information
							downloaded, added, edited, stored, processed, used, deleted on or
							from Your mobile device.
							<br />
							i) DND communication: You will be required to provide a valid
							phone number at the time of using the service through the
							platform. By registering the phone number with us, you give
							consent to be contacted by us via phone call.
						</p>
						<h6>8. ELECTRONIC COMMUNICATION</h6>
						<p>
							a) You agree to keep Yourself updated with all data, information,
							and communication pertaining to You made available on the Platform
							by the Company. You further agree that Your use of the Platform or
							provision of any data or information including any correspondence
							(by email or otherwise) to or by the Company is through electronic
							records and You consent to receive communication from the Company
							via electronic documents including emails and/or SMS, which will
							be deemed adequate for service of notice/ electronic record.
							<br />
							b) You understand You may have to bear/pay any charges associated
							with any such access (including text messaging charges for
							messages from Your mobile device). Our communications to You may
							include communication that would inform users about various
							features of our services. The Company may also send You
							promotional information unless You have opted out of receiving
							such information.
							<br />
							c) We may need to provide You with certain
							communications/notifications via voice calls or text messages,
							such as service announcements, promotional schemes and
							administrative messages. These communications are considered part
							of the Services and Your Account, and You may not be able to
							opt-out from receiving them. If You added Your phone number to
							Your account and You later change or deactivate that phone number,
							You must update Your account information to help prevent us from
							communicating with anyone who acquires Your old number.
						</p>
						<h6>9. GENERAL TERMS</h6>
						<p>
							a) All commercial/contractual terms are offered by and agreed to
							between Buyers and Merchants alone with respect to products and
							services being offered by the Merchants. The
							commercial/contractual terms include without limitation price,
							applicable taxes, shipping costs, payment terms, date, period, and
							mode of delivery, warranties related to products and services, and
							after-sales services related to products and services. The Company
							does not have any control or does not determine or advise or in
							any way or involve itself in the offering or acceptance of such
							commercial/contractual terms between the Buyers and Merchants. The
							Company may, however, offer support services to Merchants with
							respect to Order fulfilment, logistics, mode of payment, payment
							collection, and other ancillary services, pursuant to the
							understanding between the Company and the Merchants. The price of
							the products and services offered by the Merchant is solely
							determined by the Merchant itself and the Company has no role to
							play in such determination of price in any way whatsoever.
							<br />
							b) The Company does not make any representation or warranty as to
							the item-specifics (such as legal title, creditworthiness,
							identity, etc.) of any of the Merchants. You are advised to
							independently verify the bona fides of any particular Merchant
							that You choose to deal with on the Platform and use Your best
							judgment in this regard. All Merchant offers/promotions and
							third-party offers/promotions are subject to respective party
							terms and conditions and the Company takes no responsibility for
							such offers or promotions.
							<br />
							c) The Company neither makes any representation or warranty as to
							specifics (such as quality, value, saleability, etc.) of the
							products or services proposed to be sold or offered to be sold or
							purchased on the Platform nor does implicitly or explicitly
							support or endorse the sale or purchase of any products or
							services on the Platform. The Company accepts no liability for any
							errors or omissions, whether on behalf of itself or third parties
							(including the Merchants).
							<br />
							d) The Company is not responsible for any non-performance or
							breach of any contract entered into between Buyers and Merchants.
							The Merchant agrees to indemnify the Company for any and all
							Losses suffered by the Company due to Merchant’s use of the
							Platform and interactions with Buyers pursuant thereto. The
							Company cannot and does not guarantee that the concerned Buyers
							and Merchants will perform any transaction concluded on the
							Platform. The Company is not responsible for unsatisfactory or
							nonperformance of services or damages or delays as a result of
							products which are out of stock, unavailable or back ordered.
							<br />
							e) The Company is operating an online marketplace and assumes the
							role of facilitator, and does not at any point of time during any
							transaction between Buyer and Merchant on the Platform come into
							or take possession of any of the products or services offered by
							Merchant. At no time shall the Company hold any right, title or
							interest over the products nor shall the Company have any
							obligations or liabilities in respect of such contract entered
							into between Buyer and Merchant.
							<br />
							f) The Company is only providing a platform for communication and
							it is agreed that the contract for sale of any of the products or
							services shall be a strictly bipartite contract between the
							Merchant and the Buyer. In case of complaints from the Buyer
							pertaining to efficacy, quality, or any other such issues, the
							Company’s sole responsibility shall be to notify the same to the
							Merchant and shall also redirect the Buyer to the consumer call
							centre of the Merchant. The Merchant shall be solely liable for
							redressing Buyer complaints. In the event You raise any complaint
							on any Merchant accessed using our Platform, we shall assist You
							to the best of our abilities by providing relevant information to
							You, such as details of the Merchant and the specific Order to
							which the complaint relates, to enable satisfactory resolution of
							the complaint.
						</p>
						<h6>10. USER OBLIGATIONS</h6>
						<p>
							a) You agree, undertake and confirm that Your use of Platform
							shall be strictly governed by the following binding principles:
							<br />
							You shall not host, display, upload, download, modify, publish,
							transmit, update or share any information which:
							<br />
							i. is grossly harmful, harassing, blasphemous, defamatory,
							obscene, pornographic, paedophilic, libellous, slanderous,
							criminally inciting or invasive of another's privacy, hateful, or
							racially, ethnically objectionable, disparaging, relating or
							encouraging money laundering or gambling, or otherwise unlawful in
							any manner whatsoever; or unlawfully threatening or unlawfully
							harassing including but not limited to "indecent representation of
							women" within the meaning of the Indecent Representation of Women
							(Prohibition) Act, 1986;
							<br />
							ii. is patently offensive to the online community, such as
							sexually explicit content, or content that promotes obscenity,
							paedophilia, racism, bigotry, hatred or physical harm of any kind
							against any group or individual;
							<br />
							iii. involves the transmission of "junk mail", "chain letters", or
							unsolicited mass mailing or "spamming";
							<br />
							iv. promotes illegal activities or conduct that is abusive,
							threatening, obscene, defamatory or libellous;
							<br />
							v. infringes upon or violates any third party's rights including,
							but not limited to rights of privacy (including without limitation
							unauthorized disclosure of a person's name, email address,
							physical address, or phone number);
							<br />
							vi. contains restricted or password-only access pages, or hidden
							pages or images (those not linked to or from another accessible
							page);
							<br />
							vii. provides material that exploits people in a sexual, violent,
							or otherwise inappropriate manner or solicits personal information
							from anyone;
							<br />
							viii. provides instructional information about illegal activities
							such as making or buying
							<br />
							illegal weapons, violating someone's privacy, or providing or
							creating computer viruses;
							<br />
							ix. contains video, photographs, or images of another person (with
							a minor or an adult);
							<br />
							x. tries to gain unauthorized access or exceeds the scope of
							authorized access to the Platform or to profiles, blogs,
							communities, account information, bulletins, friend request, or
							other areas of the Platform or solicits passwords or personal
							identifying information for commercial or unlawful purposes from
							other Users;
							<br />
							xi. engages in commercial activities and/or sales without our
							prior written consent such as contests, sweepstakes, barter,
							advertising and pyramid schemes, or the buying or selling of
							products related to the Platform. Throughout these Terms, the
							Company’s prior written consent means a communication coming from
							the Company’s authorized representative, specifically in response
							to Your request, and expressly addressing and allowing the
							activity or conduct for which You seek authorization;
							<br />
							xii. solicits gambling or engages in any gambling activity which
							is or could be construed as being illegal;
							<br />
							xiii. interferes with another user's use and enjoyment of the
							Platform or any third party's user and enjoyment of similar
							services;
							<br />
							xiv. refers to any website or URL that, in our sole discretion,
							contains material that is inappropriate for the Platform or any
							other website, contains content that would be prohibited or
							violates the letter or spirit of these Terms;
							<br />
							xv. harm minors in any way;
							<br />
							xvi. infringes any patent, trademark, copyright, or other
							intellectual property rights of third party's trade secrets or
							rights of publicity or privacy or shall not be fraudulent or
							involve the sale of counterfeit or stolen products;
							<br />
							xvii. violates any law for the time being in force;
							<br />
							xviii. deceives or misleads the addressee/users about the origin
							of such messages or communicates any information which is grossly
							offensive or menacing in nature;
							<br />
							xix. impersonate another person;
							<br />
							xx. contains software viruses or any other computer code, files,
							or programs designed to interrupt, destroy or limit the
							functionality of any computer resource; or contains any trojan
							horses, worms, time bombs, cancelbots, easter eggs, or other
							computer programming routines that may damage, detrimentally
							interfere with, diminish the value of, surreptitiously intercept
							or expropriate any system, data or personal information;
							<br />
							xxi. threatens the unity, integrity, defense, security or
							sovereignty of India, friendly relations with foreign states, or
							public order or causes incitement to the commission of any
							criminal offense or prevents investigation of any offense, or is
							insulting any other nation;
							<br />
							xxii. is false, inaccurate, or misleading;
							<br />
							xxiii. directly or indirectly, offers attempts to offer, trades,
							or attempts to trade in any item, the dealing of which is
							prohibited or restricted in any manner under the provisions of any
							applicable law, rule, regulation, or guideline for the time being
							in force; or
							<br />
							xxiv. creates liability for us or causes us to lose (in whole or
							in part) the services of our internet service provider or other
							suppliers.
							<br />
							b) You shall not use any "deep-link", "page-scrape", "robot",
							"spider" or other automatic device, program, algorithm or
							methodology, or any similar or equivalent manual process, to
							access, acquire, copy or monitor any portion of the Platform or
							any content, or in any way reproduce or circumvent the
							navigational structure or presentation of the Platform or any
							content, to obtain or attempt to obtain any materials, documents
							or information through any means not purposely made available
							through the Platform. We reserve our right to prohibit any such
							activity.
							<br />
							c) You shall not attempt to gain unauthorized access to any
							portion or feature of the Platform, or any other systems or
							networks connected to the Platform or to any server, computer,
							network, or to any of the services offered on or through the
							Platform, by hacking, "password mining" or any other illegitimate
							means.
							<br />
							d) You shall not probe, scan or test the vulnerability of the
							Platform or any network connected to the Platform nor breach the
							security or authentication measures on the Platform or any network
							connected to the Platform. You may not reverse look-up, trace or
							seek to trace any information on any other user of or visitor to
							Platform, or any other Buyer, including any account on the
							Platform not owned by You, to its source, or exploit the Platform
							or any service or information made available or offered by or
							through the Platform, in any way where the purpose is to reveal
							any information, including but not limited to personal
							identification or information, other than Your own information, as
							provided for by the Platform.
							<br />
							e) You may not use the Platform or any content on the Platform for
							any purpose that is unlawful or prohibited by the User Agreement,
							or to solicit the performance of any illegal activity or other
							activity that infringes the rights of the Company and/or others.
							<br />
							f) You shall at all times ensure full compliance with the
							applicable provisions, as amended from time to time, of (a) the
							Information Technology Act, 2000 and the rules thereunder;
							<br />
							(b) all applicable Laws; and (c) international laws, foreign
							exchange laws, statutes, ordinances and regulations (including,
							but not limited to sales tax/VAT, income tax, general sales tax,
							central excise, custom duty, local levies) regarding Your use of
							our service and Your listing, purchase, solicitation of offers to
							purchase, and sale of products or services. You shall not engage
							in any transaction in an item or service, which is prohibited by
							the provisions of any applicable law including exchange control
							laws or regulations for the time being in force. g) In order to
							allow us to use the information supplied by You, without violating
							Your rights or any laws, You agree to grant us a non-exclusive,
							worldwide, perpetual, irrevocable, royalty-free, sub-licensable
							(through multiple tiers) right to exercise the copyright,
							publicity, database rights or any other rights You have in Your
							information, in any media now known or not currently known, with
							respect to Your information. We will only use Your Information in
							accordance with these Terms and Policies applicable to use of the
							Platform.
							<br />
							h) You shall not engage in advertising to, or solicitation of,
							other Users of the Platform to buy or sell any products or
							services, including, but not limited to, products or services
							related to that being displayed on the Platform or related to us.
							You may not transmit any chain letters or unsolicited commercial
							or junk email to other Users via the Platform. It shall be a
							violation of the User Agreement to use any information obtained
							from the Platform in order to harass, abuse, or harm another
							person, or in order to contact, advertise to, solicit, or sell to
							another person other than us without our prior explicit consent.
							You understand that we have the right, at all times, to disclose
							any information (including the identity of the persons providing
							information or materials on the Platform) as necessary to satisfy
							any law, regulation or valid governmental request. This may
							include, without limitation, disclosure of the information in
							connection with investigation of alleged illegal activity or
							solicitation of illegal activity or in response to a lawful court
							order or subpoena. In addition, We can (and You hereby expressly
							authorize us to) disclose any information about You to any law
							enforcement or other government officials, as we, in our sole
							discretion, believe necessary or appropriate in connection with
							the investigation and/or resolution of possible crimes, especially
							those that may involve personal injury.
							<br />
							i) We reserve the right, but have no obligation, to monitor the
							materials posted on the Platform. The Company shall have the right
							to remove or edit any content that in its sole discretion
							violates, or is alleged to violate, any applicable law or either
							the spirit or letter of these Terms. Notwithstanding this right,
							You remain solely responsible for the content of the materials You
							post on the platform and in Your private messages. Please be
							advised that such content posted does not reflect the Company's
							views. In no event shall the Company assume or have any
							responsibility or liability for any content posted or for any
							claims, damages or Losses resulting from use of content and/or
							appearance of the content on the Platform. You hereby represent
							and warrant that You have all necessary rights in and to all
							content which You provide and all information it contains and that
							such content shall not infringe any proprietary or intellectual
							property or other rights of third parties or contain any
							libellous, tortious, or otherwise unlawful information.
							<br />
							j) Your correspondence or business dealings with, or participation
							in promotions of, advertisers found on or through the Platform,
							including payment and delivery of related products or services,
							and any other terms, conditions, warranties or representations
							associated with such dealings, are solely between You and such
							advertiser. We shall not be responsible or liable for any loss or
							damage of any sort incurred as the result of any such dealings or
							as the result of the presence of such advertisers on the Platform.
							<br />
							k) It is possible that other Users (including unauthorized users
							or 'hackers') may post or transmit offensive or obscene materials
							on the Platform and that You may be involuntarily exposed to such
							offensive and obscene materials. It is also possible for others to
							obtain personal information about You due to Your use of the
							Platform, and that the recipient may use such information to
							harass or injure You. We do not approve of such unauthorized uses,
							but by using the Platform You acknowledge and agree that we are
							not responsible for the use of any personal information that You
							publicly disclose or share with others on the Platform. Please
							carefully select the type of information that You publicly
							disclose or share with others on the Platform.
							<br />
							l) The Company respects the intellectual property rights of others
							and expects Users of the Services to do the same. We reserve the
							right to remove content alleged to be infringing without prior
							notice, at our sole discretion, and without any liability to You.
							We will respond to notices of alleged infringement that comply
							with applicable Law and are properly provided to us. If You
							believe that Your content has been copied in a way that
							constitutes copyright infringement, please report this at
							admin@chattybao.com
							<br />
							m) The Company may in future charge a fee or charges, as intimated
							from time to time on the Platform, for registration on the
							Platform as Users especially for maintaining an official account,
							for availing services provided by the Company through the
							Platform. Currently, the Company does not levy any charges/fees
							for registration, access or maintaining an account on the
							Platform. The Company reserves the right to introduce new services
							including any premium or paid services or modify or discontinue
							any existing services provided on the Platform. Changes to the
							Terms or any of the Policies of the Company shall be published on
							the Platform and such changes shall automatically become effective
							immediately after they are published on the Platform. You are
							requested to visit the Terms and various Policies links on the
							Platform often to keep abreast of any amendments.
							<br />
							n) You confirm that these Terms hereunder (and the User Agreement)
							will not conflict with, result in a breach of or constitute a
							default (or any event that, with notice or lapse of time, or both,
							would constitute a default) or result in the acceleration of any
							obligation under any of the terms, conditions or provisions of any
							other agreement or instrument to which You are a party or by which
							You are bound or to which any of Your property or assets are
							subject, conflict with or violate any of the provisions of its
							charter documents, or violate any statute or any order, rule or
							regulation of any Authority that would materially and adversely
							affect the performance of Your duties hereunder. You have obtained
							any consent, approval, authorization of Authority required for the
							execution, delivery and performance of its respective obligations
							hereunder. If the Company suffers any Loss or damages or a claim
							is made by any Person against the Company or the Platform as a
							result of a breach or default or contravention on Your part of the
							User Agreement, You agree to, forthwith upon delivery of notice by
							the Company, make good such Losses or damages or claim amounts
							suffered by the Company.
							<br />
							o) You confirm that there is no action, suit or proceeding pending
							against You or to Your knowledge, threatened in any court or by or
							before any other Authority which would prohibit Your entering into
							or performing obligations under the User Agreement.
							<br />
							p) You confirm that You shall not transfer Your account and shall
							not assign any rights and obligations under the User Agreement to
							any third party without the specific prito written permission of
							the Company.
						</p>
						<h6>11. ACTION</h6>
						<p>
							a) In case of any violation by You of the User Agreement, the
							Company has the right to immediately terminate the access or usage
							rights of the User to the Platform without any notice and any such
							violative information that is displayed or submitted on the
							Platform may be removed immediately and completely and/or report
							to investigating authorities under applicable Law.
							<br />
							b) If the Company terminates Your access to the Platform, the
							Company may, in its sole and absolute discretion, remove and
							destroy any data and files stored by You on its servers and You
							agree and acknowledge that the Company or the Platform shall not
							in any manner be responsible and/or liable for removing or
							deleting such information.
						</p>
						<h6>12. PAYMENT</h6>
						<p>
							a) We use third party service providers or payment gateway(s) for
							processing the payments made in relation to the purchase of
							products offered by the Merchants to Buyers.
							<br />
							b) In order to enable Buyers to use paid services on the Platform,
							the Company facilitates the provision of Pay Facility by a
							third-party service provider on the Platform, at its sole
							discretion. The Pay Facility shall enable automated collection and
							remittance services using the facilities of various Indian banks,
							financial institutions, credit/ debit/ cash card brands, various
							third-party service providers and/or payment card industry issuers
							and through such other infrastructure and facilities as may be
							authorized by the Reserve Bank of India for collection, refund and
							remittance, as the case may be. The Pay Facility shall be availed
							in accordance with these Terms.
							<br />
							c) The Pay Facility may support payments through credit/debit
							cards from the banks that are available while selecting the Pay
							Facility as the payment method or by cash. The Pay Facility may
							support payments through UPI and mobile wallet payment options.
							However, payment mechanisms may be added or removed or suspended
							through any one or more banks directly or through any payment
							gateway facilitators and such change shall come into effect upon
							the same being published on such third party service provider's
							website.
							<br />
							d) The Pay Facility shall be made available to the Buyers to make
							payment easier. In case wrong bank account details are used by the
							Buyer, the Company shall not be responsible for loss of money, if
							any. In case of there being any technical failure, at the time of
							transaction and there is a problem in making payment, You could
							contact us at admin@chattybao.com, however, the Company shall not
							be liable for transaction failure or payment issues. You assume
							sole responsibility for all risks associated with transfer of
							money or payments to the Merchants, whether or not such transfer
							or payments are done through the use of a third-party payment
							gateway. All disputes regarding delayed payments or failed
							payments must be resolved by You, and the third-party payment
							gateway directly.
							<br />
							e) You agree and accept that the Company is neither acting as a
							trustee nor acting in a fiduciary capacity with respect to the
							payment transaction by reason of providing the Pay Facility or any
							other method of payment to its Buyers.
							<br />
							f) While availing any of the payment method/s available on the
							Platform, we will not be responsible or assume any liability,
							whatsoever in respect of any loss or damage arising directly or
							indirectly to You due to:
							<br />
							i. Lack of authorization for any transaction;
							<br />
							ii. Exceeding the preset limit mutually agreed by You and between
							"Banks";
							<br />
							iii. Any payment issues arising out of the transaction; or
							<br />
							iv. Decline of transaction for any other reason.
							<br />
							g) All payment transactions are subject to approval by Your
							issuing bank as applicable. If Your bank refuses to authorize
							payment, the Merchant shall have the right to cancel the Order
							without any further liability. Further, You agree that
							we/Merchants will not be held accountable for any delays caused to
							the delivery in cases where the payment authorization is delayed
							by Your bank/ financial institution.
							<br />
							h) You acknowledge and agree that You shall not use the details of
							credit card/debit card/net-banking or any other payment instrument
							which is not lawfully owned by You.
							<br />
							i) All payments made against the purchases/Services on the
							Platform by You shall be compulsorily in Indian Rupees acceptable
							in the Republic of India. The Platform shall not facilitate
							transaction with respect to any other form of currency with
							respect to the purchases made on the Platform.
							<br />
							j) The Merchants have specifically authorized the Company or its
							service providers to collect, process, facilitate and remit
							payments electronically or through "cash on delivery" to and from
							other Buyers in respect of transactions through Pay Facility.
							<br />
							k) Your relationship with the Company is on a principal to
							principal basis and by accepting these Terms, You agree that the
							Company is an independent contractor for all purposes, and does
							not have control of or liability for the products or services that
							are listed on the Platform that are paid for by using the Pay
							Facility. The Company does not guarantee the identity of any Buyer
							nor does it ensure that a Buyer or a Merchant will complete a
							transaction.
							<br />
							l) You understand, accept and agree that the Pay Facility provided
							by the Company is neither a banking nor financial service but is
							merely a facilitator providing an electronic, automated online
							electronic payment, receiving payment through "cash on delivery",
							collection and remittance facility for the transactions on the
							Platform using the existing authorized banking infrastructure and
							credit card payment gateway networks. Further, by providing the
							Pay Facility, the Company is neither acting as trustees nor acting
							in a fiduciary capacity with respect to the transaction or the
							transaction price.
							<br />
							m) Buyers acknowledge and agree that the Company acts as the
							Merchant's payment agent for the limited purpose of accepting
							payments from Buyers on behalf of the Merchant. Upon Your payment
							of amounts to us, which are due to the Merchant, Your payment
							obligation to the Merchant for such amounts is completed, and we
							are responsible for remitting such amounts to the Merchant. You
							shall not, under any circumstances whatsoever, make any payment
							directly to the Merchant for Order bookings made using the
							Platform.
							<br />
							n) Payment Facility for Buyers:
							<br />
							i. You, as a Buyer, understand that upon initiating a transaction
							You are entering into a legally binding and enforceable contract
							with the Merchant to purchase the products and /or services from
							the Merchant using the Pay Facility, and You shall pay the
							transaction price through Your issuing bank to the Merchant using
							Pay Facility.
							<br />
							ii. You, as a Buyer, shall be entitled to claim a refund of the
							transaction price from the Merchant (as Your sole and exclusive
							remedy) in case You do not receive the delivery of the goods or
							services being sold by the Merchant within the time period agreed
							in the transaction. In case You do not raise a refund claim using
							the Platform features within the stipulated time, then this would
							make You ineligible for a refund.
							<br />
							iii. You, as a Buyer, understand that refund may not be available
							in full or in part for certain category of products and/or
							services and/or transactions and hence You may not be entitled to
							a refund in respect of the transactions for those products and /or
							services.
							<br />
							iv. Except for "cash on delivery" transactions, refund, if any,
							shall be made at the same issuing bank from where the transaction
							price was received, or through any other method available on the
							third party payment gateway's website, as chosen by You.
							<br />
							v. For "cash on delivery" transactions, refunds, if any, will be
							made via electronic payment transfers in accordance with the
							policies adopted by the third party payment gateway.
							<br />
							vi. Refund shall be made in Indian Rupees only and shall be
							equivalent to the transaction price received in Indian Rupees.
							<br />
							vii. For electronics payments, refund shall be made through
							payment facility using any other online banking / electronic funds
							transfer system approved by Reserve Bank India (RBI).
							<br />
							viii. Refunds may be supported for select banks. Where a bank is
							not supported for processing refunds, You will be required to
							share alternate bank account details with for processing the
							refund.
							<br />
							ix. Refund shall be conditional and shall be with recourse
							available to the Company in case of any misuse by Buyer.
							<br />
							x. We may also request You for additional documents for
							verification.
							<br />
							xi. The Company reserves the right to impose limits on the number
							of transactions or transaction price from an individual valid
							Credit/Debit/ Cash Card / Valid Bank Account/ and such other
							infrastructure or any other financial instrument directly or
							indirectly through payment aggregator or through any such facility
							authorized by Reserve Bank of India to provide enabling support
							facility for collection and remittance of payment or by an
							individual Buyer during any time period, and reserves the right to
							refuse to process transactions exceeding such limit.
							<br />
							xii. The Company reserves the right to refuse to process
							transactions by Buyers with a prior history of questionable
							charges including without limitation breach of the User Agreement
							by Buyer with the Company or breach/violation of any law or any
							charges imposed by the issuing bank. The Buyer and Merchant
							acknowledge that the Company will not be liable for any damages,
							interests or claims etc. resulting from not processing a
							transaction/transaction price or any delay in processing a
							transaction/transaction price which is beyond control of the
							Company.
							<br />
							o) Compliance with Laws:
							<br />
							Buyer and Merchant shall comply with all the applicable Laws
							(including without limitation Foreign Exchange Management Act,
							1999 and the rules made and notifications issued thereunder and
							the Exchange Control Manual as may be issued by Reserve Bank of
							India from time to time, Customs Act, Information and Technology
							Act, 2000 as amended by the Information Technology (Amendment) Act
							2008, Prevention of Money Laundering Act, 2002 and the rules made
							there under, Foreign Contribution Regulation Act, 1976 and the
							rules made there under, Income Tax Act, 1961 and the rules made
							there under, Export Import Policy of government of India)
							applicable to them respectively for using the payment facility.
							<br />
							p) Buyer's arrangement with Issuing Bank:
							<br />
							a) All valid credit / debit/ cash card/ and other payment
							instruments are processed using a payment gateway or appropriate
							payment system infrastructure and the same will also be governed
							by the terms and conditions agreed to between the Buyer and the
							respective issuing bank and payment instrument issuing Company.
							<br />
							b) All online bank transfers from valid bank accounts are
							processed using the gateway provided by the respective issuing
							bank which support Pay Facility to provide these services to the
							Users. All such online bank transfers on Pay Facility are also
							governed by the terms and conditions agreed to between Buyer and
							the respective issuing bank.
						</p>
						<h6>13. LIMITATION OF LIABILITY</h6>
						<p>
							a) In no event shall the Company or its Personnel or its
							suppliers, service providers and internal service providers be
							liable for any direct, indirect, incidental, special,
							consequential or exemplary damages, including but not limited to,
							damages for loss of profits, goodwill, use, data or other
							intangible Losses arising (in any manner whatsoever) out of or in
							connection with the Platform, the Pay Facility, services provided
							by internal service providers on behalf of the Platform or the
							Company or any other services.
							<br />
							b) The Company’s liability under all circumstances is limited to
							the amount of charges/ fees, if any, paid by You to the Company.
							The Company, its Personnel and internal service providers and
							technology partners make no representations or warranties about
							the accuracy, reliability, completeness, and/or timeliness of any
							information or communication provided on or through the use of the
							Platform or that of the operation of the Platform or Pay Facility
							will be error free and/or uninterrupted. The Company assumes no
							liability whatsoever for any monetary or other damage suffered by
							You on account of: (a) the delay, failure, interruption, or
							corruption of any data or other information transmitted in
							connection with use of the Platform or Pay Facility; or (b) any
							delay, failure, interruption or errors in the operation of the
							Platform or Pay Facility.
						</p>
						<h6>14. INDEMNITY FOR NON-COMPLIANCE OR BREACH</h6>
						<p>
							You shall indemnify and hold harmless the Company and the
							Company's parent, subsidiaries, its Personnel, Affiliates,
							internal service providers and their respective officers,
							shareholders, directors, agents, and employees, from and against
							any and all Losses, liabilities, actions, suits, claims,
							proceedings, costs, damages, judgments, amounts paid in settlement
							and expenses (including without limitation attorneys' fees and
							disbursements), made by any third party or penalty imposed due to
							or arising out of Your breach of the User Agreement or resulting
							from untrue, inaccurate, misleading not current or incomplete
							information provided or verified by You.
						</p>
						<h6>15. CONSENT AND PRIVACY POLICY</h6>
						<p>
							a) By using the Platform and/or by providing Your Information, You
							consent to the collection and use of such Information disclosed by
							You on the Platform and on the Pay Facility (if applicable), by
							the Company. The personal information / data including but not
							limited to the information provided by You to the Platform /Pay
							Facility (if applicable) during the course of You being a
							registered User shall be retained in accordance with the Privacy
							Policy published on the Platform from time to time which is
							incorporated herein by reference and applicable Laws including but
							not limited to Information Technology Act, 2000 and rules there
							under. If You do not agree to Your information being transferred
							or used in this way, please do not use the Platform.
							<br />
							b) The Company views the protection of User's privacy as a very
							important community principle. The Company clearly understands
							that You and the personal information provided by You is one of
							the most important assets to the Company. The Company stores and
							processes the information provided by You that are protected by
							physical as well as reasonable technological security measures and
							procedures in compliance with the applicable Law.
						</p>
						<h6>16. CHANGES IN TERMS & CONDITIONS OR PRIVACY POLICY</h6>
						<p>
							The Company reserves the right to modify the Platform and/or alter
							these Terms and/or Policies at any time and retains the right to
							deny access at any time including the termination of membership
							and deletion of the account, to anyone who the Company believes
							has violated the provisions of the User Agreement.
							<br />
							You are advised that any amendment to the User Agreement
							incorporated herein by reference will only be notified on the
							Platform on publicly accessible links and You agree by accessing,
							browsing or using the Platform that such publication shall
							immediately constitute sufficient notice for all purposes against
							You. The revised version/ terms shall be effective from the time
							that the Company publishes the same on the Platform.
							<br />
							The Company shall not be liable to give any prior notice to the
							Users for any amendments to the User Agreement, which in the
							opinion of the Company, has the effect of diminishing the User's
							existing rights or imposing additional obligations. You understand
							and hereby accept that the decision, whether any proposed
							amendments to the User Agreement have the effect of diminishing
							the User's existing rights or imposing additional obligations on
							the User, shall be decided by the Company in its sole discretion.
						</p>
						<h6>17. BREACH AND TERMINATION</h6>
						<p>
							a) Without limiting other remedies that the Company may pursue,
							the Company may at its sole discretion take such action as it
							deems fit including but not limited to cancellation of the
							membership and deletion of the account , limit Your activity on
							the Platform, immediately remove Your information, or warn other
							Users of Your actions, forthwith temporarily/indefinitely suspend
							or terminate or block Your user account/membership, and/or refuse
							to provide You with access to the Platform or initiate any legal
							action it may deem fit, particularly in the event: You breach any
							of the provisions of the User Agreement, any of the documents,
							agreements between the Company and You in addition to these Terms,
							terms and conditions made thereunder which are incorporated
							therein by reference; any misuse of Your account or the Pay
							Facility (if utilised), in case the Company is unable to verify or
							authenticate any information provided by You, if the Company
							believes that Your actions may cause legal liability to the
							Company, other Users or Yourself.
							<br />
							b) No actions, omissions or decisions taken by the Company shall
							waive any rights or claims that the Company may have against the
							User. Any User that may have been suspended or blocked may not
							register or attempt to register with the Platform or use the
							Platform in any manner whatsoever until such time that such User
							is reinstated by the Company. Notwithstanding the above, if You
							breach the Terms or the Policies and other documents incorporated
							therein by reference or any other agreements entered into by the
							Company and You in addition to the Terms, the Company reserves the
							right to take strict legal action including but not limited to
							referral to the appropriate police or other authorities for
							initiating criminal or civil or other proceedings against You.
							<br />
							c) You agree and acknowledge that we, in our sole discretion,
							without prior notification or intimation, may terminate your
							access to all or part of the Platform or cease to provide
							accessibility to the Platform, for reasons including but not
							limited to:
							<br />
							i. In response to the requests of law enforcement agencies or
							other government institutions;
							<br />
							ii. In response to your own request;
							<br />
							iii. The suspension or major modification of the website or any of
							its services;
							<br />
							iv. Unforeseeable technical issues;
							<br />
							v. Occurrence of an event outside our control.
							<br />
							d) All Your obligations and liabilities incurred prior to the
							termination date shall survive the termination of the User
							Agreement for all purposes.
						</p>
						<h6>18. GRIEVANCE REDRESSAL MECHANISM</h6>
						<p>
							a) In case of any grievance, objection or complaint on Your part
							with respect to the Platform, other Users or the Company,
							including any complaints or enquiry about suspension, termination
							or blocking of Your membership or right to use the Platform, You
							should promptly raise such grievance or complaint with the
							designated Grievance Officer at admin@chattybao.com and provide
							the Grievance Officer with all necessary information and/or
							documents to enable the Company/Grievance Officer to try and
							resolve the issue.
							<br />
							b) The Grievance Officer shall attempt to acknowledge your
							grievances/ complaints within 48 (forty-eight) hours of receiving
							it. The Grievance Officer may requisite such information as he may
							require in order to look into the grievances/ complaints for
							resolving the same. The Grievance Officer shall attempt to resolve
							the grievance/ complaint within 1 (one) month of having received
							it. You acknowledge that in the event you fail to provide the
							information sought by the Grievance Officer to look into your
							complaint, the Grievance Officer shall not be able to proceed with
							your grievance/ complaint, and as such, the grievance/ complaint
							may remain unresolved. By agreeing to the Terms herein, you hereby
							consent and authorize the Grievance Officer to contact you on the
							basis of the information provided by you, for the purposes of the
							grievance/ complaint.
						</p>
						<h6>19. CONFIDENTIALITY</h6>
						<p>
							a) All communications between the You and the Company and all
							confidential information given to or received by You from the
							Company, and all information concerning the business transactions
							of the Company with any entity or person with whom it may or may
							not have a confidentiality obligation with regard to the matter in
							question, shall be kept confidential by You (whether or not such
							information or data has been marked as confidential) unless
							specifically permitted to the contrary in writing to the Company.
							<br />
							b) Further the Company may at any time disclose any confidential
							information on a confidential basis to any prospective and current
							investors, strategic or financial, partners or service providers
							other than other Users of the Platform.
							<br />
							c) This confidentiality obligation shall survive the termination
							of the agreement with and the User account of the concerned User.
						</p>
						<h6>20. SEVERABILITY</h6>
						<p>
							If any provision of the User Agreement or the application thereof
							to any person or circumstance is determined to be invalid,
							unlawful, void or unenforceable to any extent for any reason
							including by reason of any applicable Law, the remainder of the
							User Agreement and the application of such provision to persons or
							circumstances other than those as to which it is held invalid or
							unenforceable shall not be affected thereby, and each provision of
							the User Agreement shall be valid and enforceable to the fullest
							extent permitted by Law. Any invalid or unenforceable provision of
							the User Agreement shall be replaced with a provision, which is
							valid and enforceable and most nearly reflects the original intent
							of the invalid and unenforceable provision.
						</p>
						<h6>21. GOVERNING LAW AND JURISDICTION</h6>
						<p>
							The User Agreement and documents incorporated by reference shall
							be governed and construed in accordance with the laws of India. If
							any dispute arises between You and the Company during Your use of
							the Platform or the Pay Facility or any service incidental to the
							Platform or thereafter, in connection with the validity,
							interpretation, implementation or alleged breach of any provision
							of the Terms and/or any other agreement between the Company and
							You in addition to the User Agreement and any other documents
							incorporated therein by reference, the dispute shall be referred
							to the senior management of the Company for conciliation. If the
							dispute has not been resolved, then such a dispute shall be
							subject to the exclusive jurisdiction of the courts in New Delhi
							and You hereby submit to the jurisdiction of such courts.
						</p>
						<h6 style={{ textAlign: "center" }}>
							Banned and Restricted Products List:*
						</h6>
						<p>
							1. Adult Material
							<br />
							2. Alcohol, Wine & Liquor
							<br />
							3. Animal & Wildlife products or hides/skins/teeth, nails and
							other parts etc. of animals
							<br />
							4. Antiques & Artifacts
							<br />
							5. Beta Software
							<br />
							6. Bootleg / Pirated Software
							<br />
							7. Catalogue & URL sales
							<br />
							8. Used Clothing
							<br />
							9. Compilation & Information Media
							<br />
							10. Contact Information and User databases
							<br />
							11. Contracts
							<br />
							12. Copyrights
							<br />
							13. Counterfeit Currency & Stamps
							<br />
							14. Downloadable media (Unless expressly permitted by Myntra)
							<br />
							15. Drugs
							<br />
							16. Electronic / Digital Surveillance Equipment (Unless permitted
							by law)
							<br />
							17. Event Tickets
							<br />
							18. Firearms, Ammunition, Militaria & Weapons
							<br />
							19. Explosives & Explosive substances
							<br />
							20. Games Software
							<br />
							21. Government ID's and Licenses
							<br />
							22. Hazardous & restricted products
							<br />
							23. Human parts & remains
							<br />
							24. Importation of Products - examples include CDs that were
							intended only for distribution in a certain country
							<br />
							25. Products encouraging illegal activities
							<br />
							26. Misleading titles
							<br />
							27. Movie prints
							<br />
							28. Offensive material
							<br />
							29. Police, Army, Navy & Air Force related Products
							<br />
							30. Prohibited services
							<br />
							31. Promotional Products
							<br />
							32. Real Estate
							<br />
							33. Replica & counterfeit Products
							<br />
							34. Ringtones
							<br />
							35. Stolen property
							<br />
							36. Tobacco & related products
							<br />
							37. Trademarks & Patents
							<br />
							38. Travel
							<br />
							39. Cable descramblers and black boxes which includes devices
							intended to obtain cable and satellite signals for free
							<br />
							40. Copyright unlocking devices which includes mod chips or other
							devices designed to circumvent copyright protection
							<br />
							41. Drug test circumvention aids which includes drug cleansing
							shakes, urine test additives, and related Products
							<br />
							42. Gaming/gambling which includes lottery tickets, sports bets,
							memberships/ enrolment in online gambling sites, and related
							content
							<br />
							43. Hacking and cracking materials which includes manuals, how-to
							guides, information, or equipment enabling illegal access to
							software, servers, watomites, or other protected property
							<br />
							44. Illegal products which includes materials, products, or
							information promoting illegal products or enabling illegal acts
							<br />
							45. Miracle cures which include unsubstantiated cures, remedies or
							other Products marketed as quick health fixes
							<br />
							46. Offensive products which includes literature, products or
							other materials that: 47. Defame or slander any person or groups
							of people based on race, ethnicity, national origin, religion,
							sex, or other factors b) Encourage or incite violent acts c)
							Promote intolerance or hatred
							<br />
							48. Prescription drugs or herbal drugs or any kind of online
							pharmacies which includes drugs or other products requiring a
							prescription by a licensed medical practitioner.
							<br />
							49. Pyrotechnic devices and hazardous materials which includes
							fireworks and related products; toxic, flammable, and radioactive
							materials and substances
							<br />
							50. Regulated products which includes air bags; batteries
							containing mercury; freon or similar substances/refrigerants,
							chemical/industrial solvents, government uniforms, car titles or
							logos, license plates, police badges and law enforcement
							equipment, lock-picking devices, pesticides; postage meters,
							recalled Products, slot machines, surveillance equipment; products
							regulated by government or other agency specifications
							<br />
							51. Securities, which includes stocks, bonds, or related financial
							products
							<br />
							52. Tobacco and cigarettes which includes cigarettes, cigars,
							chewing tobacco, and related products
							<br />
							53. Traffic devices which includes radar detectors/ jammers,
							license plate covers, traffic signal changers, and related
							products
							<br />
							54. Wholesale currency which includes discounted currencies or
							currency exchanges
							<br />
							55. Multi-Level marketing collection fees
							<br />
							56. Matrix sites or sites using a matrix scheme approach
							<br />
							57. Work-at-home information
							<br />
							58. Drop-shipped merchandise
						</p>
						<h6>
							*The above list is merely indicative and not exhaustive. Chattybao
							may at its sole discretion, amend or modify this list.
						</h6>
					</div>
				</div>
			</div>
			<div
				className="container1"
				style={{
					backgroundImage: `url(${orange})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundPosition: "revert",
				}}
			>
				<div className="con-2">
					<div className="con-2__content">
						<h1>Have a Business ?</h1>
						<h2>5 minute Sign up</h2>
						<div className="con-2__link">
							<a
								href="https://play.google.com/store/apps/details?id=com.goping.merchant"
								target="_blank"
								rel="noreferrer"
							>
								<img src={img7} alt="" />
							</a>
							<div className="con-2__link-content">
								<a
									href="https://play.google.com/store/apps/details?id=com.goping.merchant"
									target="_blank"
									rel="noreferrer"
								>
									<p>Download Now</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserAgreement;
