import { createSlice } from "@reduxjs/toolkit";

export const reduxStore = createSlice({
  name: "counter",
  initialState: {
    totalPrice: "",
    oldTotalPrice: "",
    savings: "",
    totalCount: "",
    summaryData: {},
    riderLatLng: {},
    openPhone: false,
    whatsappOpen: false,
    loginPage: false,
    iframeLoader: false,
    merchantDetails: null,
    multipleStores: [],
    merchantSelected: false,
    fromMenu: false,
  },
  reducers: {
    setState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState } = reduxStore.actions;

export default reduxStore.reducer;
